import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../styles/DetailCourse/DetailCourse.css";
import BoxReason from "../Home/BoxReason";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import FormRegister from "../../components/UI/FormRegister/FormRegister";
import FormRegisterContact from "../../components/UI/FormRegisterContact/FormRegisterContact";
import BoxPartners from "../Home/BoxPartners";
import BoxCommunity from "../Home/BoxCommunity";
import { Helmet } from "react-helmet";
import { ConfigContext } from "../../Context/ConfigContext";

const DetailCourse = () => {
  const { slug, id } = useParams();
  const [detailCourse, setDetailCourse] = useState({});
  const [relateCourse, setRelateCourse] = useState([]);

  const [feelStudent, setfeelStudent] = useState([]);

  useEffect(() => {
    // api chi tiết khóa học
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/courses/detail/${id}`).then((response) => {
      setDetailCourse(response.data.data);
    });
    // api khóa học liên quan
    axios(`${process.env.REACT_APP_API_ENDPOINT}/courses/relate/${id}`).then((response) => {
      setRelateCourse(response.data.data);
    });
  }, [id]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/feel-students`).then((response) => {
      setfeelStudent(response.data.data);
    });
  }, []);
  const dataConfig = useContext(ConfigContext)
  const configs = dataConfig.config
  const feelStudentDesc = configs.feel_students_description;
  // setting slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <div className="box-detail-courses">
      <Header />
      <Helmet>
        <title>{detailCourse.title}</title>
      </Helmet>
      <main>
        <section className="banner no-padding">
          <div className="inner-header-title">
            <img src={detailCourse.image_url} className="img-fluid" />
          </div>
        </section>
        <div className="breadcrumb-wrap">
          <div className="container">
            <ul className="breadcrumb justify-content-center  ">
              <li className="breadcrumb-item">
                <Link to="/">Trang chủ</Link>
              </li>
              <li className="breadcrumb-item active">Chi tiết khóa học</li>
            </ul>
          </div>
        </div>
        <section className="p-0">
          <div className="container">
            <div className="section-title section-title-course">
              <div className="title text-violet course-title">
                <h2>
                  <span>{detailCourse.title}</span>
                </h2>
              </div>
            </div>
            <div className="section-content">
              <div dangerouslySetInnerHTML={{ __html: detailCourse?.content }} className="section-content-des"></div>
              <div className="course-feature-des">Kết quả đạt được sau khóa học:</div>
              <p>{detailCourse.degree}</p>
              <div className="course-feature-des">Thời lượng của khóa học:</div>
              <p>{detailCourse.time}</p>
              <div className="course-feature-des">Khóa học dành cho ai?</div>
              <p>{detailCourse.object}</p>

              {/* dưới là api Khóa học liên quan */}
            </div>
          </div>
        </section>
        {relateCourse.length != 0 ? (
          <section className="related-courses-wrap">
            <div className="container">
              <div className="related-courses">Các khóa học liên quan</div>
              <div className="course-content">
                <div className="row p-0">
                  {relateCourse?.map((item, index) => (
                    <div className="col-lg-4 col-md-6" key={index}>
                      <div className="course-item box-shadow">
                        <div className="course-item-img">
                          <Link to={`/chi-tiet-khoa-hoc/${item.slug}/${item.id}`}>
                            <img src={item.image_url} className="img-fluid" title={item.title} />
                          </Link>
                        </div>
                        <div className="course-item-title">
                          <h3 title={item.title}>
                            <Link to={`/chi-tiet-khoa-hoc/${item.slug}/${item.id}`}>{item.title}</Link>
                          </h3>
                          <p>{item.time}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {/* <BoxReason /> */}
        <section className="sdc-feel-students bg-primary ">
          <div className="container">
            <div className="section-title">
              <div className="title text-white text-center mb-0">
                <h2>
                  <span>Cảm nhận học viên</span>
                </h2>
              </div>
              <p className="text-white text-center ">{feelStudentDesc}</p>
            </div>
            <div className="section-content">
              <Slider {...settings}>
                {feelStudent?.map((student, index) => (
                  <div className="text-center feel-student" key={index}>
                    <div className="comm-item-img">
                      <img src={student.avatar_url} className="img-fluid" />
                    </div>
                    <h3 className="text-white">{student.name}</h3>
                    <p>
                      <em dangerouslySetInnerHTML={{ __html: student.content }}></em>
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>
        <BoxCommunity />
        <FormRegisterContact />
        <BoxPartners />
      </main>

      <Footer />
    </div>
  );
};

export default DetailCourse;
