import React, { useContext, useEffect, useState, useRef } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { ConfigContext } from '../../Context/ConfigContext';
import '../../styles/ReadMoreSigned/ReadMoreSigned.css'
import SideBar from '../SideBar/SideBar';
import axios from 'axios';
import bannerSigned from "../../assets/image/ky-ket-hop-tac/banner.jpg";
import { Link, useSearchParams  } from "react-router-dom";
import { Helmet } from 'react-helmet';
const ReadMoreSigned = () => {
  const dataConfig = useContext(ConfigContext)
  const configs = dataConfig.config
  const title = configs.kykethoptac
  const [dataSigned, setDataSigned] = useState([]);
  const dataList = dataSigned.slice(1)
  const slugNews = configs?.slug_project
  // phân trang
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastPage, setLastPage] = useState(0) // trang cuối
  const page = searchParams.get("page");
    let idPageNumber = Number((page));
    //sroll to element
    const scrollSection = useRef();
    const scrollHanler = (elmRef) => {
      window.scrollTo({top: elmRef.current.offsetTop - 160, behavior: "smooth"});
      
    }
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/news?category_id=999&page=${idPageNumber}&per_page=6`)
      .then((response) => {
        setDataSigned(response.data.data.data);
        setLastPage(response.data.data.last_page);
      });
  }, [idPageNumber])

  const prevPage = () =>idPageNumber - 1;;
  const nextPage = () => idPageNumber + 1;
   // xử lý Pagination
   const pageNumbers =[];
   for (let i = 1; i<= lastPage; i++){
       pageNumbers.push(i)
   }
  return (
    <div>
      <Header />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <section className="section-banner-signed">
          <div className="inner-header-title">
            <img src={bannerSigned} className="img-fluid" alt="" />
          </div>
        </section>
      </div >
      <div className='breadcrumb-wrap pt-2' ref={scrollSection}>
        <div className='container'>
          <ul className='breadcrumb p-0'>
            <li className='breadcrumb-item'>
              <Link to='/'>Trang chủ</Link>
            </li>
            <li className='breadcrumb-item active'>{title}</li>
          </ul>
        </div>
      </div>
      <section className='section-list-signed'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-7 com-sm-12 p-0'>
              <div className='main-content'>
                <article className='panel-article panel-shadow appear mb0'>
                  <div className='panel-inner'>
                    <h1 className='article-title-lg'>
                      <Link to={`/${dataSigned[0]?.slug}/`}>{dataSigned[0]?.title}</Link>
                    </h1>
                    <p dangerouslySetInnerHTML={{ __html: dataSigned[0]?.content }}></p>
                    <div className='full-image'>
                      <Link to={`/${dataSigned[0]?.slug}/`}>
                        <img src={dataSigned[0]?.image_urls} />
                      </Link>
                    </div>

                    <ul className='blog-list p-0'>
                      {dataList?.map((data, index) => (
                        <li key={index}>
                          <div className='media media-blog pt0 appear'>
                            <div className='align-self-center blog-images img mask-cover loaded'>
                              <Link to={`/${data?.slug}/`}>
                                <img src={data.image_urls}  />
                              </Link>
                            </div>
                            <div className='media-body'>
                              <h3 className='product-entries-ttl'>
                                <Link to={`/${data?.slug}/`}>{data.title}</Link>
                              </h3>
                              <p dangerouslySetInnerHTML={{ __html: data.content }}></p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </article>
              </div>
            </div>
            <div className='col-lg-4 col-md-5 com-sm-12'>
              <SideBar />
            </div>
          </div>
        </div>
      </section>

      {/* pagination */}
      <div className='mb-4'>
          <nav>
            <div className='pagination justify-content-center'>
              {
                idPageNumber !== 1 && (
                  <li onClick={() => scrollHanler(scrollSection)}>
                    <Link to={`/ky-ket-hop-tac/xem-them?page=${idPageNumber - 1}`}>
                      <button
                        className="button-page"
                        style={{ cursor: "pointer" }}
                        type="primary"
                        onClick={() => prevPage()}>
                        <i className="fa-solid fa-angles-left"></i>
                      </button>
                    </Link>
                  </li>
                )
              }
              {

                pageNumbers.map((num) => (

                  <li className='page-item' key={num} onClick={() => scrollHanler(scrollSection)}>

                    <Link to={`/ky-ket-hop-tac/xem-them?page=${num}`}
                      className={idPageNumber === num ? "number-page active-button" : "number-page"}
                      style={{ cursor: "pointer" }}
                    >
                      {num}
                    </Link>
                  </li>
                ))
              }
              {
                pageNumbers.length !== idPageNumber && (
                  <li onClick={() => scrollHanler(scrollSection)}>
                    <Link to={`/ky-ket-hop-tac/xem-them?page=${idPageNumber + 1}`}>
                      <button
                        style={{ cursor: "pointer" }}
                        className="button-page"
                        type="primary"
                        onClick={() => nextPage()}>
                        <i className="fa-solid fa-angles-right"></i>
                      </button></Link>
                  </li>
                )
              }
            </div>
          </nav>
        </div>
      <Footer />
    </div>
  )
}

export default ReadMoreSigned