import React, { useEffect, useState, useContext, useRef } from "react";
import Header from "../../components/Header/Header";
import { Helmet } from "react-helmet";
import bannerTest from "../../assets/image/IntroduceSDC/BannerSDCIntroduce.png";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment/moment";
import { Link, useSearchParams } from "react-router-dom";
import "../../styles/ScheduleExam/ScheduleExam.css";
import axios from "axios";
import { CourseContext } from "../../Context/CoursesContext";
import BannerDetail from "../../components/UI/BannerDetail/BannerDetail";
import { FeatureContext } from "../../Context/FeatureContext";
import { ConfigContext } from "../../Context/ConfigContext";
import Footer from "../../components/Footer/Footer";

const ScheduleExam = () => {
  const dataConfig = useContext(ConfigContext);
  const configs = dataConfig.config;
  const [schedule, setSchedule] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const dataCourse = useContext(CourseContext);
  const dataFeature = useContext(FeatureContext);
  const titleScheduleExam = configs?.title_schedule_exam;
  const titleDesciption = configs?.title_desciption;
  const featuresNews = dataFeature?.dataFeature;
  const course = dataCourse.course;
  const [lastPageSchedule, setLastPageSchedule] = useState(0);
  const page = searchParams.get("page");
  const pageidSchedule = Number(page);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/news?slug_category=lich-thi&page=${pageidSchedule}&per_page=6`).then((response) => {
      setSchedule(response.data.data.data);
      setLastPageSchedule(response.data.data.last_page);
    });
  }, [pageidSchedule]);
  const prevPage = () => pageidSchedule - 1;
  const NextPage = () => pageidSchedule + 1;
  const pageNumbersSchedule = [];
  for (let i = 1; i <= lastPageSchedule; i++) {
    pageNumbersSchedule.push(i);
  }
  //sroll to ref
  const scrollNew = useRef();
  const scrollHanler = (elmRef) => {
    window.scrollTo({ top: elmRef.current.offsetTop, behavior: "smooth" });
  };
  return (
    <div>
      <Header />
      <Helmet>
        <title>{titleScheduleExam}</title>
      </Helmet>
      <BannerDetail imgBanner={bannerTest} titleH2={titleScheduleExam} />
      <section ref={scrollNew}>
        <Container className="container-schedule">
          <Row>
            <ul className="box-schedule">
              <li className="item-schedule">
                <Link to="/">Trang chủ</Link>
              </li>
              <li className="item-schedule">
                <span>{titleScheduleExam}</span>
              </li>
            </ul>
          </Row>
          <Row>
            <Col lg="8">
              <div className="header-thematic">
                <h5 className="title-thematic m-0">{titleScheduleExam}</h5>
                <p className="descript-thematic">{titleDesciption}</p>
              </div>
              {schedule?.map((schedule) => (
                <div className="card-schedule" key={schedule.id}>
                  <div>
                    <div>
                      <div className="card-schedule-wrapper">
                        <div className="card-schedule-date">
                          <span className="card-schedule-month">Tháng {schedule && moment(schedule?.created_at).format("MM")}</span>
                          <span className="card-schedule-day"> {schedule && moment(schedule?.created_at).format("DD")}</span>
                        </div>
                        <div className="card-schedule-title">
                          <h2 title={schedule.title}>
                            <Link to={`/chi-tiet-bai-viet/${schedule.slug}`}>{schedule.title} </Link>
                          </h2>

                          <div className="card-schedule-folder">
                            <i className="fa-regular fa-circle-user"></i>
                            {schedule?.users?.name}
                          </div>
                        </div>
                      </div>

                      <p className="card-schedule-des">{schedule.summary}</p>
                      <Link to={`/chi-tiet-bai-viet/${schedule.slug}`} className="read-more-schedule">
                        Xem chi tiết <i className="fa-solid fa-angle-right"></i>
                      </Link>
                      <p className="card-schedule-time">
                        {" "}
                        <i className="fa-solid fa-calendar-days"></i> {schedule && moment(schedule?.created_at).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              <div>
                <nav className="pagination-schedule">
                  <div className="pagination justify-content-center">
                    {pageidSchedule !== 1 && (
                      <li onClick={() => scrollHanler(scrollNew)}>
                        <Link to={`/lich-thi?page=${pageidSchedule - 1}`}>
                          <button className="button-page" style={{ cursor: "pointer" }} type="primary" onClick={() => prevPage()}>
                            <i className="fa-solid fa-angles-left"></i>
                          </button>
                        </Link>
                      </li>
                    )}
                    {pageNumbersSchedule.map((num) => (
                      <li className="page-item" key={num} onClick={() => scrollHanler(scrollNew)}>
                        <Link to={`/lich-thi?page=${num}`} className={pageidSchedule === num ? "number-page active-button" : "number-page"} style={{ cursor: "pointer" }}>
                          {num}
                        </Link>
                      </li>
                    ))}
                    {pageNumbersSchedule.length !== pageidSchedule && (
                      <li onClick={() => scrollHanler(scrollNew)}>
                        <Link to={`/lich-thi?page=${pageidSchedule + 1}`}>
                          <button style={{ cursor: "pointer" }} className="button-page" type="primary" onClick={() => NextPage()}>
                            <i className="fa-solid fa-angles-right"></i>
                          </button>
                        </Link>
                      </li>
                    )}
                  </div>
                </nav>
              </div>
            </Col>
            <Col lg="4">
              <div className="thematic-category">
                <h2 className="thematic-category-title">CÁC KHÓA HỌC</h2>
                <div className="thematic-menu">
                  <ul>
                    {course &&
                      course.map((courseItem) => (
                        <li key={courseItem.id}>
                          <Link to={`/chi-tiet-khoa-hoc/${courseItem.slug}/${courseItem.id}`}>
                            {" "}
                            <i className="fa-solid fa-angle-right"></i>
                            {courseItem.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>

              <div className="news-category">
                <h2 className="news-category-title">TIN TỨC NỔI BẬT</h2>
                <div className="news-category-menu">
                  <ul>
                    {featuresNews &&
                      featuresNews.map((item, index) => (
                        <li key={index}>
                          <i className="fa-solid fa-calendar-days"></i>
                          {item && moment(item?.created_at).format("DD/MM/YYYY")}
                          <div className="news-category-menu-title">
                            {" "}
                            <Link to={`/chi-tiet-bai-viet/${item.slug}/`} key={index}>
                              {item.title}
                            </Link>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default ScheduleExam;
