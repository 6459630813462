import React, { useEffect, useState, useContext } from 'react';
import '../../styles/HomePage/BoxReason.css'
import axios from 'axios';
import { ConfigContext } from "../../Context/ConfigContext";
import { Link } from 'react-router-dom';

const BoxReason = () => {
  const dataConfig = useContext(ConfigContext)
  const configs = dataConfig.config
  const titleService = configs?.service
  const [services, setServices] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/services`)
      .then(response => {
        setServices(response.data.data)
      })
  }, [])
  return (
    <section className="sdc-reason">
      <div className="container">
        <div className="section-title">
          <div className="title text-violet">
            <h2>
              <span>{titleService}</span>
            </h2>
          </div>
        </div>
        <div className="section-content">
          <div className="section-row row">
            {services?.map((service, index) => (
              <div className='col-lg-4 text-center' key={index}>
                <div className='text-box'>
                  <div className='img'>
                    <img src={service.logo_url} />
                  </div>
                  <div className='title-content text-violet'>
                    <h3>{service.title}</h3>
                  </div>
                  <div className='description-content'>
                    <p>{service.description}</p>
                  </div>
                  <div className='button-content'>
                    <div className='row justify-content-center'>
                      {service.links.map((item, index) => (
                        <div style={{ width: 'auto', padding: '0 4px' }} key={index}>
                      
                          <Link to={item.url} target='_blank' >
                            <button className='btn btn-light'>
                              <img src={item.icon} />
                              <strong>{item.name}</strong>
                            </button>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BoxReason