import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../styles/DetailLectures/DetailLectures.css";
import "../../styles/DetailStudents/DetailStudents.css";
import { ConfigContext } from "../../Context/ConfigContext";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import axios from "axios";

const DetailStudents = () => {
  const dataConfig = useContext(ConfigContext);
  const configs = dataConfig?.config;
  const titleStudents = configs?.title_detail_students;
  const { slug } = useParams();
  const [detailStudents, setDetailStudents] = useState();
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/students/detail/${slug}`).then((response) => {
      setDetailStudents(response.data.data);
    });
  }, []);
  return (
    <div>
      <Header />
      <Helmet>
        <title>{titleStudents}</title>
      </Helmet>
      <main>
        <section>
          <div className="section-title m-0">
            <div className="title text-violet m-0 ">
              <h2>
                <span>{titleStudents}</span>
              </h2>
            </div>
          </div>
        </section>
        <div className="detail-students">
          <div className="container">
            <div className="row p-0">
              <div className="col-sm-4">
                <div className="students-featured-img">
                  <img src={detailStudents?.avatar_urls} />
                </div>
              </div>
              <div className="col-sm-8">
                <div className="students-wrapper">
                  <div className="students-heading">
                    <h1 className=" students-fullName">{detailStudents?.fullname}</h1>
                    <div className="fw-bold color-heading student-position">{detailStudents?.position}</div>
                    <div className="student-workplace">{detailStudents?.workplace}</div>
                  </div>

                  <div className="students-information">
                    <div className="students-description" dangerouslySetInnerHTML={{ __html: detailStudents?.description }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default DetailStudents;
