import React, { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import bannerProject from "../../assets/image/IntroduceSDC/BannerSDCIntroduce.png";
import "../../styles/ReadMoreProject/ReadMoreProject.css";
import BoxReason from "../Home/BoxReason";
import BannerDetail from "../../components/UI/BannerDetail/BannerDetail";
import CardProject from "../../components/UI/CardProject/CardProject";
import { Link, useSearchParams } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import { Helmet } from "react-helmet";

const ReadMoreProject = () => {
  const dataConfig = useContext(ConfigContext);
  const titleProduct = dataConfig?.config.project_student_title;
  const [MoreProject, SetDataMoreProject] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0); // trang cuối
  const page = searchParams.get("page");
  let idPageNumber = Number(page);

  //sroll to element
  const scrollSection = useRef();
  const scrollHanler = (elmRef) => {
    window.scrollTo({ top: elmRef.current.offsetTop, behavior: "smooth" });
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/project-students?page=${idPageNumber}&per_page=6`).then((res) => {
      SetDataMoreProject(res.data.data.data);
      setLoading(false);
      setLastPage(res.data.data.last_page);
    });
  }, [idPageNumber]);
  const prevPage = () => idPageNumber - 1;
  const nextPage = () => idPageNumber + 1;
  // xử lý Pagination
  const pageNumbers = [];
  for (let i = 1; i <= lastPage; i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      <Header />

      <Helmet>
        <title>{titleProduct}</title>
      </Helmet>
      <BannerDetail imgBanner={bannerProject} titleB="Làm trước" titleP="học sau" titleH2="Sản phẩm học viên" />

      <BoxReason />
      <section className="product" ref={scrollSection}>
        <div className="container">
          <CardProject data={!loading ? MoreProject : "loading..."} />
        </div>

        <div>
          <nav>
            <div className="pagination justify-content-center">
              {idPageNumber !== 1 && (
                <li onClick={() => scrollHanler(scrollSection)}>
                  <Link to={`/du-an-hoc-vien/san-pham?page=${idPageNumber - 1}`}>
                    <button className="button-page" style={{ cursor: "pointer" }} type="primary" onClick={() => prevPage()}>
                      <i className="fa-solid fa-angles-left"></i>
                    </button>
                  </Link>
                </li>
              )}
              {pageNumbers.map((num) => (
                <li className="page-item" key={num} onClick={() => scrollHanler(scrollSection)}>
                  <Link to={`/du-an-hoc-vien/san-pham?page=${num}`} className={idPageNumber === num ? "number-page active-button" : "number-page"} style={{ cursor: "pointer" }}>
                    {num}
                  </Link>
                </li>
              ))}
              {pageNumbers.length !== idPageNumber && (
                <li onClick={() => scrollHanler(scrollSection)}>
                  <Link to={`/du-an-hoc-vien/san-pham?page=${idPageNumber + 1}`}>
                    <button style={{ cursor: "pointer" }} className="button-page" type="primary" onClick={() => nextPage()}>
                      <i className="fa-solid fa-angles-right"></i>
                    </button>
                  </Link>
                </li>
              )}
            </div>
          </nav>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ReadMoreProject;
