import React, { useEffect, useState, useContext } from "react";
import Iframe from "react-iframe";
import '../../styles/Footer/footer.css'
import logoSDC from "../../assets/image/LOGO SDC/LogoSDCMobile.jpg";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { ConfigContext } from "../../Context/ConfigContext";
import { MenuContext } from "../../Context/MenuContext";
import { SettingContext } from "../../Context/SettingContext";
import { CategoriesContext } from "../../Context/CategoriesContext";

const Footer = () => {
  const dataConfig = useContext(ConfigContext)
  const dataMenuFooter = useContext(MenuContext);
  const footerMenu = dataMenuFooter?.menu?.slice(0, 5)
  const configs = dataConfig.config
  const settingConfig = useContext(SettingContext);
  const slugCategories = useContext(CategoriesContext)
  const categories_slug = slugCategories.categories[0]?.slug
  const  setting = settingConfig.setting
  const phoneNumber = configs?.hotline
  const email = configs?.email_address
  const address = configs?.address
  const urlFacebook = configs?.url_fb_sdc
  const titleSystem = configs?.title_system
  const urlPersonVue = configs?.url_person_vue
  const urlSoftware = configs?.url_software_sdc


  return (
    <footer className="footer-layout">
      <div className="footer-wrapper">
        <div className="container">
          <div className="top-footer text-center">
            <div className="footer-logo d-flex align-items-end justify-content-center">
              <div className="logo-item">
                <Link to="/">
                  <img src={logoSDC} className="img-fluid" alt="" />
                </Link>
              </div>
            </div>
            <h2>{titleSystem}</h2>
            <div className="row justify-content-center bottom-titleContact">
              <Col lg="4" md="6" sm="8" xs="10" className="footerContact">
                <div className="d-flex justify-content-around">
                  <span>
                    <Link to={`tel: ${phoneNumber}`}>
                      <i className="footerContact-Icon fa-solid fa-phone"></i> HOTLINE: <b>{phoneNumber}</b>
                    </Link>
                  </span>
                  <span>
                    <Link to={urlFacebook} target='_blank'>
                      <i className="footerContact-Icon fa-brands fa-facebook-f"></i>
                    </Link>
                  </span>
                  <span>
                    <Link to={`mailto:${email}`}>
                      <i className="footerContact-Icon fa-regular fa-envelope"></i>
                    </Link>
                  </span>

                </div>
              </Col>
            </div>
          </div>
          <div className="mid-footer">
            <div className="row">
              <div className="col-lg-7 col-xl-5 col-md-8">
                <div className="mid-footer-item">
                  <h3>Liên hệ</h3>
                  <ul className="nav-f-contact">

                    <li >
                      <Link to={`tel: ${phoneNumber}`}>
                        <div className="row contact-row">
                          <div className="contact-icon col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2">
                            <i className="fa-solid fa-phone" ></i>
                          </div>
                          <div className="contact-text col-10 col-sm-11 col-md-11 col-lg-10 col-xl-10">{phoneNumber}</div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to={`mailto:${email}`}>
                        <div className="row contact-row">
                          <div className="contact-icon col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2">
                            <i className=" fa-regular fa-envelope-open"  ></i>
                          </div>
                          <div className="contact-text col-10 col-sm-11 col-md-11 col-lg-10 col-xl-10">{email}</div>
                        </div>
                      </Link>
                    </li>
                    <li >
                      <div className="row contact-row">
                        <div className="contact-icon col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2">
                          <i className="fa-regular fa-map"  ></i>
                        </div>
                        <div className="contact-text col-10 col-sm-11 col-md-11 col-lg-10 col-xl-10">{address}</div>
                      </div>
                    </li>
                  </ul> 
                </div>
              </div>
              <div className="col-lg-5 col-xl-3 col-md-4">
                <div className="row menu-row">
                  <div className="col-md-12 menu-footer">
                    <div className="mid-footer-item">
                      <h3>VỀ SDC</h3>
                      <ul>
                        {footerMenu &&
                          footerMenu.map((menuFooter, index) => (
                            <li key={index}>
                              <Link 
                              to={`${menuFooter.slug === 'phan-mem' ? `${urlSoftware}` : menuFooter.slug === 'dao-tao' ? `/tin-tuc-su-kien/${categories_slug}?page=1` : `${menuFooter.slug === 'khao-thi' ? `${urlPersonVue}` : `/${menuFooter.slug}`}`}`}
                              target={`${menuFooter.slug === 'phan-mem' ? `_blank` : `${menuFooter.slug === 'khao-thi' ? `_blank` : ""}`}`}>
                                {menuFooter.title}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-4 col-md-12">
                <div className="mid-footer-item">
                  <Iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.895552434537!2d108.21799931416994!3d16.07090894366332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314218314b9a71b7%3A0x3aa3d7cc91ab2372!2zNDEgxJAuIEzDqiBEdeG6qW4sIEjhuqNpIENow6J1IDEsIEjhuqNpIENow6J1LCDEkMOgIE7hurVuZyA1NTAwMDAsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1676877785598!5m2!1svi!2s"
                    height="300"
                    style="border: 0"
                    allowfullscreen="true"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="address-location"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
