import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import { CategoriesContext } from "../../Context/CategoriesContext";

const SubMenuParentDestop = ({ data }) => {
  const dataConfig = useContext(ConfigContext);
  const configs = dataConfig.config;
  const urlSoftware = configs?.url_software_sdc;
  const urlPersonVue = configs?.url_person_vue;
  const slugCategories = useContext(CategoriesContext);
  const categories_slug = slugCategories.categories[0]?.slug;

  return (
    <>
      <li className="dropdownmenu">
        <NavLink
          to={`${data.slug === "phan-mem" ? `${urlSoftware}` : data.slug === "dao-tao" ? `/tin-tuc-su-kien/${categories_slug}?page=1` : `${data.slug === "khao-thi" ? `${urlPersonVue}` : `/${data.slug}`}`
            }`}
          target={`${data.slug === "phan-mem" ? `_blank` : `${data.slug === "khao-thi" ? `_blank` : ""}`}`}
        >
          {data.title}
        </NavLink>

        {data.slug === "dao-tao" ? (
          <ul className="sub-menu">
            {/* <li className="dropdownmenu m-0">
              <Link to="/giang-vien">giảng viên - chuyên gia</Link>
            </li>
            <li className="dropdownmenu m-0">
              <Link to="/cuu-hoc-vien">cựu học viên</Link>
            </li>
            <li className="dropdownmenu m-0">
              <Link to="/du-an-hoc-vien/san-pham?page=1">sản phẩm học viên</Link>
            </li>
            <li className="dropdownmenu m-0">
              <Link to="/lich-thi?page=1">lịch thi</Link>
            </li>
            <li className="dropdownmenu m-0">
              <Link to="/khoa-hoc">khóa học</Link>
            </li> */}
            {data.children.map((item, index) => (
              <li className="dropdownmenu m-0" key={index}>
                <Link
                  to={`${item.slug === "san-pham-hoc-vien" ? `/du-an-hoc-vien/san-pham?page=1` : item.slug === "lich-thi" ? `/lich-thi?page=1`: item.slug === "lich-khai-giang" ? `/lich-khai-giang?khoa-hoc=lap-trinh` :  `/${item.slug}`}`}
                >{item.title}</Link>
              </li>
            ))}
          </ul>

        ) : (
          ""
        )}
      </li>
    </>
  );
};

export default SubMenuParentDestop;
