import React, { useContext } from 'react'
import software_icon from "../../../assets/image/IntroduceSDC/software.png"
import exam_icon from "../../../assets/image/IntroduceSDC/exam.png"
import edu_icon from "../../../assets/image/IntroduceSDC/edu.png"
import "./ServiceIntroduce.css"
import { Col, Container, Row } from "react-bootstrap";
import { ConfigContext } from '../../../Context/ConfigContext';
import { Link } from 'react-router-dom'
import { CategoriesContext } from '../../../Context/CategoriesContext'
const ServiceIntroduce = () => {
    const dataConfig = useContext(ConfigContext)
    const configs = dataConfig.config
    const  servicevalue =configs?.service
    const  softwarevalue =configs?.txt_software
    const  educationvalue = configs?.txt_education
    const  examvalue = configs?.txt_exam
    const urlSoftware = configs?.url_software_sdc
    const urlPersonVue = configs?.url_person_vue
    const slugCategories = useContext(CategoriesContext)
    const categories_slug = slugCategories.categories[0]?.slug
  return (
    <>
            <section>
        <Container>
          <Row>
            <div className="section-title service-title">
              <div className="title text-violet">
                <h2>
                  <span>{servicevalue}</span>
                </h2>
              </div>
            </div>
          </Row>
          <Row className="d-flex align-items-center justify-content-center">
            <Col lg="4" sm="6">
             <Link to={urlSoftware} target='_blank'>
             <div className="card-history">
                <div className="card-img">
                  <img src={software_icon} alt="iconBook" />
                </div>
                <h4>{softwarevalue}</h4>
              </div>
             </Link>
            </Col>
            <Col lg="4" sm="6">
             <Link to={`/tin-tuc-su-kien/${categories_slug}?page=1`}>
             <div className="card-history">
                <div className="card-img">
                  <img src={edu_icon} alt="iconBook" />
                </div>
                <h4>{educationvalue}</h4>
              </div>
             </Link>
            </Col>
            <Col lg="4"  sm="6">
             <Link to={urlPersonVue} target='_blank'>
             <div className="card-history">
                <div className="card-img">
                  <img src={exam_icon} alt="iconBook" />
                </div>
                <h4>{examvalue}</h4>
              </div>
             </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ServiceIntroduce
