import React, { useContext, useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import bannerStudent from '../../assets/image/listStudent/cuuhocvien.jpg'
import { ConfigContext } from '../../Context/ConfigContext'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const ListStudent = () => {
    const dataConfig = useContext(ConfigContext)
    const configs = dataConfig?.config
    const description = configs?.students_description?.slice(9)
    const strongDescription = configs?.students_description?.slice(0, 8)
    const titleStudents = configs?.student_community
    const [students, setstudents] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/students`)
            .then((response) => {
                setstudents(response.data.data);
            })
    }, [])
    return (
        <div>
            <Header />
            <Helmet>
                <title>{titleStudents}</title>

            </Helmet>
            <main>
                <section className='banner no-padding'>
                    <div className='inner-header-title'>

                        <img src={bannerStudent} className='img-fluid' />
                    </div>
                </section>
                <section className='section-lectures-content'>
                    <div className='container'>
                        <div className='section-title m-0'>
                            <div className='title text-violet m-0 '>
                                <h2>
                                    <span>{titleStudents}</span>
                                </h2>
                            </div>
                        </div>
                        <div className='section-description'>
                            <div className='row justify-content-center text-center'>
                                <div className='col-lg-11 col-md-8'>
                                    <p >
                                        <strong>{strongDescription}</strong>&nbsp;
                                        {description}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <section>
                            <div className='row team'>
                                {students?.map((student, index) => (
                                    <div className='col-lg-4 col-md-6' key={index}>
                                        <div className='box-img'>
                                            <Link to={`/chi-tiet-hoc-vien/${student.slug}`}>
                                                <img src={student.avatar_urls} />
                                            </Link>

                                        </div>
                                        <div className='box-text text-center '>
                                            <div className='box-text-inner'>
                                                <h5 className='px-5'>
                                                    <Link to={`/chi-tiet-hoc-vien/${student.slug}`}>{student.fullname}</Link>
                                                </h5>
                                                <p className='fw-bold color-heading m-0'>{student.position}</p>
                                                <p className='fst-italic'>{student.workplace}</p>
                                                {/* <p className='fw-bold teacher-major'>{teacher.profession}</p>
                                                <p dangerouslySetInnerHTML={{ __html: teacher.description }} className='teacher-description fst-italic'></p> */}

                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>

                        </section>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default ListStudent