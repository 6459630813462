import React, {useEffect} from 'react'

const CommentsFB = (props) => {
    useEffect(() => {
        function initFacebookSDK() {
            if (window.FB) {
                window.FB.XFBML.parse();
            }
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js#version=v2.2&appId=myAppId&xfbml=true&autoLogAppEvents=true';
                fjs.parentNode.insertBefore(js, fjs)
            }(document, 'script', 'facebook-jssdk'));
        }

        initFacebookSDK()
    }, [props.dataHref])
    return (
        <div>
            <h4>Bình luận trên facebook:</h4>
            <div className="fb-comments w-100" data-href={`${process.env.REACT_APP_URL}/chi-tiet-bai-viet/${props.dataHref}/`} data-width="100%" data-numposts="5"></div>
        </div>
    )
}

export default CommentsFB