import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment/moment';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import axios from 'axios';
import '../../styles/KeyWord/KeyWord.css'
const KeyWord = () => {
    const [news, setNews] = useState([])
    const { keyword } = useParams()
    const [lastPage, setLastPage] = useState(0); // trang cuối
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page");
    let idPageNumber = Number(page);
    //sroll to element
  const scrollSection = useRef();
  const scrollHanler = (elmRef) => {
    window.scrollTo({ top: elmRef.current.offsetTop, behavior: "smooth" });
  };
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/news?page=${idPageNumber}&per_page=6&keyword=${keyword}`)
            .then(response => {
                setNews(response.data.data.data);
                setLastPage(response.data.data.last_page);
            })
    }, [idPageNumber])
    const prevPage = () => idPageNumber - 1;
    const nextPage = () => idPageNumber + 1;
    // xử lý Pagination
    const pageNumbers = [];
    for (let i = 1; i <= lastPage; i++) {
        pageNumbers.push(i);
    }
    return (
        <div>
            <Header />
            <main ref={scrollSection}>
                <section className='box-content-blog-detail bg-section'  >
                    <div className='container'>
                        <div className='text-result-keyword'>
                            <i className="fa-solid fa-circle-info"></i>
                            Kết quả tìm kiếm cho từ khóa:
                            '<strong>{keyword}</strong>'
                        </div>
                        <div className='box-more-blog'  >
                            <div className='row'>
                                {news && news.map(item => (
                                    <div className='col-lg-4 col-md-6  mb-5' key={item.id}>
                                        <div className='item box-shadow-item'>
                                            <div className='row flex-column h-100'>
                                                <Link to={`/chi-tiet-bai-viet/${item.slug}`}>
                                                    <div className='col-md-12 col-xs-12 img-log'>
                                                        <img src={item.image_urls} style={{ width: '100%' }} />
                                                    </div>
                                                </Link>

                                                <div className='col-md-12 col-xs-12 flex-auto'>
                                                    <div className='small-content-blog d-flex flex-column h-100  '>
                                                        <div className='content-small flex-auto'>
                                                            <Link to={`/chi-tiet-bai-viet/${item.slug}`}>
                                                                <h4 className='my-3'>{item.title}</h4>
                                                            </Link>

                                                            <span className='date-blog'>{moment(item.created_at).format('DD/MM/YYYY')}</span>
                                                            <div className='des__sapo'>
                                                                <p>
                                                                    <strong>{item.summary}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='read-more-blog'>
                                                            <Link to={`/chi-tiet-bai-viet/${item.slug}`}>

                                                                <p>
                                                                    Xem thêm
                                                                    <i className='fa fa-angle-right'></i>
                                                                </p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div >
                        </div>
                        <div className="pagination justify-content-center">
                            {idPageNumber !== 1 && (
                                <li onClick={() => scrollHanler(scrollSection)}>
                                    <Link to={`/result-keyword/${keyword}?page=${idPageNumber - 1}`}>
                                        <button className="button-page" style={{ cursor: "pointer" }} type="primary" onClick={() => prevPage()}>
                                            <i className="fa-solid fa-angles-left"></i>
                                        </button>
                                    </Link>
                                </li>
                            )}
                            {pageNumbers.map((num) => (
                                <li className="page-item" key={num} onClick={() => scrollHanler(scrollSection)}>
                                    <Link to={`/result-keyword/${keyword}?page=${num}`} className={idPageNumber === num ? "number-page active-button" : "number-page"} style={{ cursor: "pointer" }}>
                                        {num}
                                    </Link>
                                </li>
                            ))}
                            {pageNumbers.length !== idPageNumber && (
                                <li onClick={() => scrollHanler(scrollSection)}>
                                    <Link to={`/result-keyword/${keyword}?page=${idPageNumber + 1}`}>
                                        <button style={{ cursor: "pointer" }} className="button-page" type="primary" onClick={() => nextPage()}>
                                            <i className="fa-solid fa-angles-right"></i>
                                        </button>
                                    </Link>
                                </li>
                            )}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default KeyWord