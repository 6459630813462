import React, { useContext } from 'react'
import { Col, Container, Row } from "react-bootstrap";
import "../../styles/IntroduceCss/Introduce.css"
import bannerSDC from "../../assets/image/IntroduceSDC/BannerSDCIntroduce.png"
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import data_icon from "../../assets/image/IntroduceSDC/computer-icon.png"
import idea_icon from "../../assets/image/IntroduceSDC/idea-icon.png"
import manage_icon from "../../assets/image/IntroduceSDC/manage-icon.png"
import introSDC from "../../assets/image/IntroduceSDC/introduce-img.jpg"
import { Link } from 'react-router-dom';
import BoxLecturers from '../Home/BoxLecturers';
import BoxPartners from '../Home/BoxPartners';
import { ConfigContext } from '../../Context/ConfigContext';
import ServiceIntroduce from '../../components/UI/ServiceIntroduce/ServiceIntroduce';
import BannerDetail from '../../components/UI/BannerDetail/BannerDetail';
import { SettingContext } from '../../Context/SettingContext';
import { Helmet } from 'react-helmet';
const Introduce = () => {
  const dataConfig = useContext(ConfigContext);

  const settingConfig = useContext(SettingContext);
  const setting = settingConfig.setting
  const configs = dataConfig.config
  const titleIntro = dataConfig?.config.introduce
  const visionvalue = configs?.gt_tamnhin
  const missionvalue = configs?.gt_sumenh
  const corevalue = configs?.gt_giatricotloi
  const visiontext = configs?.txt_tamnhin
  const missiontext = configs?.txt_sumenh
  const coretext = configs?.txt_giatricotloi

  const contentSetting = setting[0]?.content_introduce;

  return (
    <>
      <Header />
      <Helmet>
        <title>{titleIntro}</title>
        
      </Helmet>
      <BannerDetail imgBanner={bannerSDC} titleH2="Giới Thiệu" />
      <section>
        <div className='container'>
          <ul className='box-breadcrumbs p-0'>
            <li className='item-breadcrumb'>

              <Link to='/'>Trang chủ</Link>
            </li>
            <li className='item-breadcrumb'>
              <span>Giới thiệu</span>
            </li>
          </ul>
        </div>
        <Container>
          <h2 className='text-center title-contact text-uppercase'>{titleIntro}</h2>
          <div className='introduce-content '>
            <Row className='text-center introduce-row'>
              <Col className='col-md-12'>
                <p className="introduce-des" dangerouslySetInnerHTML={{ __html: contentSetting }}></p>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <ServiceIntroduce />
      <section>
        <Container className='mission'>
          <div className='mission-Title'>
            <h2 className='mission-heading'>{visionvalue}</h2>
            <div className='mission-space'></div>
            <h2 className='mission-heading'>{missionvalue}</h2>
            <div className='mission-space'></div>
            <h2 className='mission-heading'>{corevalue}</h2>
          </div>
          <Row className='align-items-center mission-row'>
            <Col lg="5" >
              <Row className='mission-info'>
                <Col xs="1" className='mission-icon'>
                  <img src={data_icon} alt='mission-icon' />
                </Col>
                <Col xs="11" className='mission-content'>
                  <h5>
                    {visionvalue}
                  </h5>
                  <p>{visiontext}</p>
                </Col>

              </Row>
              <Row className='mission-info'>
                <Col xs="1" className='mission-icon'>
                  <img src={idea_icon} alt='mission-icon' />
                </Col>
                <Col xs="11" className='mission-content'>
                  <h5>
                    {missionvalue}
                  </h5>
                  <p>{missiontext}</p>
                </Col>

              </Row>
              <Row className='mission-info'>
                <Col xs="1" className='mission-icon'>
                  <img src={manage_icon} alt='mission-icon' />
                </Col>
                <Col xs="11" className='mission-content'>
                  <h5>
                    {corevalue}
                  </h5>
                  <p>{coretext}</p>
                </Col>
              </Row>
            </Col>
            <Col lg="7" >
              <img src={introSDC} alt="introSDC" className='w-100' />
            </Col>

          </Row>
        </Container>
      </section>
      <BoxLecturers />
      <BoxPartners />
      <Footer />
    </>
  )
}

export default Introduce
