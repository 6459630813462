import React from "react";
import { Container } from "react-bootstrap";
import "./BannerDetail.css";

const BannerDetail = (props) => {
  return (
    <main>
      <section className="section-banner banner-detail pt-112">
      <div className="inner-header-title">
        <img src={props.imgBanner} className="img-fluid" alt="" />
        <div className="inner-header-title-caps title-detailBanner">
          <Container>
            <p>
              <b>{props.titleB}</b> {props.titleP}
            </p>
            <h2>{props.titleH2}</h2>
          </Container>
        </div>
      </div>
    </section>
    </main>
  );
};

export default BannerDetail;
