import React, { useContext, useEffect, useState } from 'react'
import '../../styles/DetailLectures/DetailLectures.css'
import { useParams } from 'react-router-dom'
import { ConfigContext } from '../../Context/ConfigContext'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Helmet } from 'react-helmet'
import axios from 'axios'

const DetailLectures = () => {
    const dataConfig = useContext(ConfigContext)
    const configs = dataConfig?.config
    const { slug } = useParams()
    const titleLecturers = configs?.title_detail_lectures

    const [detailLectures, setDetailLectures] = useState()
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/teachers/detail/${slug}`)
            .then((response) => {
                setDetailLectures(response.data.data);
            })
    },[])

  

    return (
        <div>
            <Header />
            <Helmet>
                <title>{titleLecturers}</title>
            </Helmet>
            <main>
                <section>
                    <div className='section-title m-0'>
                        <div className='title text-violet m-0 '>
                            <h2>
                                <span>{titleLecturers}</span>
                            </h2>
                        </div>
                    </div>
                </section>

                <div className='detail-lectures'>
                    <div className='container'>
                        <div className='row p-0' >
                            <div className='col-sm-4'>
                                <div className='lectures-featured-img'>
                                    <img src={detailLectures?.avatar_urls} />
                                </div>
                            </div>
                            <div className='col-sm-8'>
                                <div className='lectures-wrapper'>
                                    <div className='lectures-heading'>
                                        <h1 className=''>{detailLectures?.fullname}</h1>
                                        <div className='fw-bold color-heading mb-3'>{detailLectures?.profession}</div>
                                    </div>
                                    
                                    <div className='lectures-information'>
                                        <div className='lectures-description' dangerouslySetInnerHTML={{ __html: detailLectures?.description }}></div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default DetailLectures