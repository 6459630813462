import React, { useContext, useRef, useEffect, useState } from "react";
import "./Floatingbtn.css";
import regis_icon from "../../assets/image/IconsFloatingButton/register-icon.png";
import face_icon from "../../assets/image/IconsFloatingButton/Facebook-icon.png";
import mess_icon from "../../assets/image/IconsFloatingButton/message-icon.png";
import zalo_icon from "../../assets/image/IconsFloatingButton/zalo-icon.png";
import { Link } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import { CourseContext } from "../../Context/CoursesContext";
const FloatingButton = () => {
  const [ip, setIp] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const captcha = useRef(null);
  const [caps, setcaps] = useState(false);
  const dataCourse = useContext(CourseContext);
  const courseTitle = dataCourse.course;
  const options = courseTitle.map((option) => ({
    id: option.id,
    label: option.title,
    value: option.title,
  }));
  const defaultValues = {
    courseOptions: "",
  };
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isDirty, isSubmitting, isValid },
  } = useForm({
    defaultValues,
  });
  const dataConfig = useContext(ConfigContext);
  const configs = dataConfig.config;
  const urlFacebook = configs?.url_fb_sdc;
  const zalovalue = configs?.zalo;
  const closeFrom = useRef(null);

  const toggleMenu = () => {
    closeFrom.current.classList.toggle("show__form");
    document.body.classList.toggle("hiddenScroll");
  };

  useEffect(() => {
    axios.get("https://geolocation-db.com/json/'").then((response) => {
      setIp(response.data.IPv4);
    });
    document.addEventListener("keydown", function (e) {
      if (e.key === "Escape" && closeFrom.current.classList.contains("show__form")) {
        closeFrom.current.classList.remove("show__form");
        document.body.classList.remove("hiddenScroll");
      }
    });
  }, []);
  const onSubmit = (data) => {
    const singleValue = getValues("courseOptions");
    data.course_id = singleValue.id;
    data.ip_address = ip; // thêm địa chỉ IP
    delete data.courseOptions;
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/consultations/store`, data).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message);
        setIsLoading(false);
        reset();
        captcha.current.reset();
        setcaps(false);
        toggleMenu();
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsLoading(false);
        captcha.current.reset();
        setcaps(false);
      }
    });
  };
  const onChange = () => {
    if (captcha.current.getValue()) {
      setcaps(true);
    }
  };
  let minOffset = 0,
    maxOffset = 48;
  let thisYear = new Date().getFullYear() - 5;
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears.map((x) => {
    return x;
  });
  return (
    <>
      <div className="fab-container">
        <div className="fab-icon fab-icon-holder fab-icon-menu">
          <i className="fa-solid fa-bars"></i>
        </div>

        <ul className="fab-options">
          <Link to="#">
            <li>
              {" "}
              <span className="fab-label">Đăng ký</span>
              <div className="fab-icon-holder" onClick={toggleMenu}>
                <img src={regis_icon} alt="register-icon" className="social-icon" />
              </div>
            </li>
          </Link>

          <Link to={`https://zalo.me/${zalovalue}`} target="_blank">
            <li>
              {" "}
              <span className="fab-label">Zalo</span>
              <div className="fab-icon-holder">
                <img src={zalo_icon} alt="zalo_icon" className="social-icon" />
              </div>
            </li>
          </Link>
          <Link to={urlFacebook} target="_blank">
            <li>
              {" "}
              <span className="fab-label">Facebook</span>
              <div className="fab-icon-holder">
                <img src={face_icon} alt="face_icon" className="social-icon" />
              </div>
            </li>
          </Link>
        </ul>
      </div>
      <div className="form-container" ref={closeFrom}>
        <div className="form-register-advise">
          <form className="subscribe-form" onSubmit={handleSubmit(onSubmit)}>
            <h3 className="text-violet title-form">ĐĂNG KÝ NHẬN TƯ VẤN MIỄN PHÍ</h3>

            <div className="form__Group">
              <label htmlFor="fullName" className="lableSubcribe">
                Họ tên<i className="scv-2">(*)</i>
              </label>
              <input
                autoComplete="nope"
                type="text"
                name="fullName"
                className="form-control"
                id="fullName"
                placeholder="Họ tên"
                {...register("name", {
                  required: true,
                  minLength: 2,
                  maxLength: 50,
                })}
              />
            </div>

            {Object.keys(errors).length !== 0 && (
              <div>
                {errors.name?.type === "required" && (
                  <div className="errorsWarning">Vui lòng điền Họ tên của bạn</div>
                )}
                {(errors.name?.type === "minLength" || errors.name?.type === "maxLength") && (
                  <div className="errorsWarning">
                    Họ tên của bạn không hợp lệ vui lòng kiểm tra lại!
                  </div>
                )}
              </div>
            )}
            <div className="form__Group">
              <label htmlFor="phone" className="lableSubcribe">
                Email<i className="scv-2">(*)</i>
              </label>
              <input
                autoComplete="nope"
                type="email"
                name="email"
                className="form-control"
                id="email"
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
              />
            </div>
            {Object.keys(errors).length !== 0 && (
              <div>
                {" "}
                {errors.email?.type === "required" && (
                  <div className="errorsWarning">Vui lòng điền Email của bạn!</div>
                )}
                {errors.email?.type === "pattern" && (
                  <div className="errorsWarning">Email không hợp lệ vui lòng kiểm tra lại</div>
                )}
              </div>
            )}

            <div className="form__Group">
              <label htmlFor="phone" className="lableSubcribe">
                Số điện thoại<i className="scv-2">(*)</i>
              </label>
              <input
                autoComplete="nope"
                type="text"
                name="phone"
                className="form-control"
                id="phone"
                placeholder="Số điện thoại"
                min="0"
                onKeyPress={(event) => {
                  if (!/[0-9+]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                {...register("phone", {
                  required: true,
                  minLength: 10,
                  maxLength: 12,
                  pattern: /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})/,
                })}
              />
            </div>
            {Object.keys(errors).length !== 0 && (
              <div>
                {errors.phone?.type === "required" && (
                  <div className="errorsWarning">Vui lòng điền số điện thoại của bạn!</div>
                )}
                {(errors.phone?.type === "minLength" ||
                  errors.phone?.type === "maxLength" ||
                  errors.phone?.type === "pattern") && (
                  <div className="errorsWarning">
                    Số điện thoại không hợp lệ vui lòng kiểm tra lại!
                  </div>
                )}
              </div>
            )}

            <div className="form__Group">
              <label htmlFor="address" className="lableSubcribe">
                Địa chỉ<i className="scv-2">(*)</i>
              </label>
              <input
                autoComplete="nope"
                type="text"
                name="address"
                className="form-control"
                id="address"
                placeholder="Địa chỉ"
                {...register("address", {
                  required: true,
                  minLength: 2,
                  maxLength: 100,
                })}
              />
            </div>
            {Object.keys(errors).length !== 0 && (
              <div>
                {errors.address?.type === "required" && (
                  <div className="errorsWarning">Vui lòng điền địa chỉ của bạn</div>
                )}
                {(errors.address?.type === "minLength" || errors.address?.type === "maxLength") && (
                  <div className="errorsWarning">Địa chỉ không hợp lệ vui lòng kiểm tra lại!</div>
                )}
              </div>
            )}

            <div className="form__Group">
              <label htmlFor="year" className="lableSubcribe">
                Năm sinh<i className="scv-2">(*)</i>
              </label>
              <select
                className="form-control-select"
                id="year"
                {...register("year_of_birth", { required: true })}
              >
                <option className="lableSubcribe" value="">
                  Chọn năm sinh của bạn
                </option>
                {yearList &&
                  yearList.map((yearOption, index) => (
                    <option className="form-control" value={yearOption.id} key={index}>
                      {yearOption}{" "}
                    </option>
                  ))}
              </select>
            </div>
            {Object.keys(errors).length !== 0 && (
              <div>
                {" "}
                {errors.year_of_birth?.type === "required" && (
                  <div className="errorsWarning">Vui lòng chọn năm sinh của bạn!</div>
                )}
              </div>
            )}

            <div className="form__Group">
              <label htmlFor="course" className="lableSubcribe">
                Khóa học<i className="scv-2">(*)</i>
              </label>
              <Controller
                name="courseOptions"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    placeholder="Chọn khóa học cần tư vấn"
                    isClearable
                    {...field}
                    options={options}
                  />
                )}
              />
              {errors.courseOptions && (
                <div className="errorsWarning">Vui lòng chọn khóa học cần tư vấn</div>
              )}
            </div>
            <div className="recaptcha">
              <ReCAPTCHA
                className="captcha"
                ref={captcha}
                sitekey="6LdZL1glAAAAABoJb4N7b0qtxDRp21n9UenQuuyx"
                onChange={onChange}
                onExpired={() => {
                  setcaps(false);
                }}
              />
            </div>
            <div className="form__Group btn-required">
              <button
                className="btn"
                type="submit"
                disabled={isSubmitting || !isDirty || !caps || !isValid || loading}
              >
                {loading ? "Đang gửi đăng ký..." : "Đăng ký"}
              </button>
            </div>
          </form>
          <div className="form__close" onClick={toggleMenu}>
            <button className="form__close-btn" disabled={loading}>
              <i className="fa-sharp fa-regular fa-circle-xmark"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FloatingButton;
