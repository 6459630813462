import React, { useContext } from "react";
import "./App.css";
import "./styles/reponsive/reponsive.css";
import FloatingButton from "./components/FloatingButton/FloatingButton";
import BackToTopButton from "./pages/Home/BackToTopButton";
import Hotline from "./pages/Hotline";
import Routers from "./router/Routers";
import { MenuProvider } from "./Context/MenuContext";
import { SettingProvider } from "./Context/SettingContext";

import { CourseProvider } from "./Context/CoursesContext";
import { SettingContext } from "./Context/SettingContext";
import { Helmet } from "react-helmet";
import { FeatureProvider } from "./Context/FeatureContext";
function App() {
  const settingConfig = useContext(SettingContext);
  const settings = settingConfig?.setting;

  return (
    <div>
      <Helmet>
        <title>{settings[0]?.name}</title>
      </Helmet>
      <MenuProvider>
        <CourseProvider>
          <FeatureProvider>
            <Routers />
            <FloatingButton />
            <BackToTopButton />
            <Hotline />
          </FeatureProvider>
        </CourseProvider>
      </MenuProvider>
    </div>
  );
}

export default App;
