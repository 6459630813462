import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const CourseContext = createContext({});

export const CourseProvider = ({ children }) => {
  const [course, setCourse] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/courses`).then((response) => {
      setCourse(response.data.data);
    });
  }, []);

  return <CourseContext.Provider value={{ course }}>{children}</CourseContext.Provider>;
};
