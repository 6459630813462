import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider } from "./Context/ConfigContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { CategoriesProvider } from "./Context/CategoriesContext";
import { SettingProvider } from "./Context/SettingContext";
import { CategoriesCoursesProvider } from "./Context/CategoriesCoursesContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

  <Router basename={process.env.PUBLIC_URL || ''}>
    <ConfigProvider>
      <CategoriesProvider>
        <ToastContainer theme="light" position="top-right" autoClose={3000} closeOnClick pauseOnHover={false} />
        <SettingProvider>
        <CategoriesCoursesProvider>
        <App />
          </CategoriesCoursesProvider>
        </SettingProvider>
      </CategoriesProvider>
    </ConfigProvider>
  </Router>

);
