import React, { useEffect, useState, useContext } from "react";
import "../../styles/HomePage/BoxSigned.css";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import { ConfigContext } from "../../Context/ConfigContext";


const BoxSigned = () => {
  const dataConfig = useContext(ConfigContext)
  const configs = dataConfig.config
  const titleSigned = configs?.kykethoptac
  const [dataSigned, setDataSigned] = useState([]);
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/news?category_id=999&per_page=4`)
        .then((response) => {
      setDataSigned(response.data.data.data);
    });
  }, []);

  return (
    <section className="news bg-overlay pb-148">
      <div className="container">
        <div className="news-title">
          <div className="title text-white text-center">
            <h2>
              <b>{titleSigned}</b>
            </h2>
          </div>
        </div>
        <div className="news-content pb-80">
          <div className="row">
            <div className="col-lg-8">
              <div className="news-feature row">
                <div className="col-lg-9">
                  <div className="news-item-active">
                    <Link to={`/chi-tiet-bai-viet/${dataSigned[0]?.slug}/`}>
                      <div className="new__img-thumb">
                        <img src={dataSigned[0]?.image_urls} alt="" className="w-100" />
                      </div>
                    </Link>
                    <div className="div-read-more">
                      <div className="text-right">
                        <Link to={`/chi-tiet-bai-viet/${dataSigned[0]?.slug}/`} className="but-warning but-normal">
                          Xem chi tiết
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 no-padding">
              {dataSigned &&
                dataSigned?.map((item, index) => (
                  <div className="news-item" key={index}>
                    <div className="d-flex align-items-center">
                      <div className="col-md-4 no-padding">
                        <Link to={`/chi-tiet-bai-viet/${item.slug}/`}>
                          <div className="news-item-img">
                            <img src={item.image_urls} alt="" />
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          <Link to={`/chi-tiet-bai-viet/${item.slug}/`}>{item.title}</Link>
                        </h3>
                        <div className="d-flex justify-content-between">
                          <span>{moment(item.created_at).format("DD/MM/YYYY")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="text-right">
                <Link to='/ky-ket-hop-tac/xem-them?page=1' className="but-warning but-normal but-list-signed">
                  Xem thêm
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoxSigned;
