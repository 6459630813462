import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const CategoriesCoursesContext = createContext({});

export const CategoriesCoursesProvider = ({children}) => {
    const [categories, setCategories] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/course-categories`)
            .then(response => {
                setCategories(response.data.data)
            })
           
    },[])
    return <CategoriesCoursesContext.Provider value={{categories}}>
        {children}
    </CategoriesCoursesContext.Provider>
}
