import { createContext, useState, useEffect } from "react";
import axios from "axios";
export const SettingContext = createContext({});

export const SettingProvider = ({ children }) => {
    const [setting, setSetting] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/settings`)
            .then(response => {
                setSetting(response.data.data);
                
                localStorage.setItem("logo", response.data.data[0].logo_url);
            })
    }, [])

    return <SettingContext.Provider value={{setting}}>
        {children}
    </SettingContext.Provider>
}
