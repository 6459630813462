import React, { useRef, useState, useEffect, useContext } from "react";
import '../../styles/HomePage/BoxCommunity.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { ConfigContext } from '../../Context/ConfigContext';

const BoxCommunity = () => {
  const dataConfig = useContext(ConfigContext)
  const configs = dataConfig.config
  const titleCommunity = configs?.student_community
  const [dataStudent, SetDataStudent] = useState([]);
  // API students
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/students`).then((res) => {
      SetDataStudent(res.data.data);
    });

  }, []);
  let quantityStudent = dataStudent.length;
  if (quantityStudent >= 4) {
    quantityStudent = 3;
  } else if (quantityStudent < 4) {
    quantityStudent = quantityStudent - 1;
  } else if (quantityStudent <= 2) {
    quantityStudent = 1;
  }
  const settings = {
    dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: quantityStudent,
    swipeToSlide: true,
    focusOnSelect: true,
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    appendDots: (dots) => <ul data-label="Cựu học viên">{dots}</ul>,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: true,
          slidesToShow: quantityStudent - 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };
  return (
    <section className="student">
      <div className="container">
        <div className="section__student-title">
          <div className="title text-violet text-center">
            <h2>
              <span>{titleCommunity}</span>
            </h2>
          </div>
        </div>
        <div className="student-content">
          <Slider {...settings}>
            {dataStudent &&
              dataStudent.map((student, index) => (
                <div key={index}>
                  <div className="comm-item">
                    <div className="comm-item-img">
                      <img src={student.avatar_urls} className="img-fluid lazy" alt="" />
                    </div>
                    <h3>{student.fullname}</h3>
                    <p>{student.position}</p>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default BoxCommunity;
