
import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
    const [menu, setMenu] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/menus`)
            .then(response => {
                setMenu(response.data.data);
               
            })
    }, [])

    return <MenuContext.Provider value={{menu}}>
        {children}
    </MenuContext.Provider>
}
