import React, { useContext } from "react";
import "./FormRegisterContact.css";
import face_contact from "../../../assets/image/IconsFloatingButton/Facebook-icon.png";
import zalo_contact from "../../../assets/image/IconsFloatingButton/zalo-icon.png";
import gmail_contact from "../../../assets/image/IconsFloatingButton/Gmail-logo.png";
import tu_van_vien from "../../../assets/image/tu-van-vien/tuvanvien.png";
import { ConfigContext } from "../../../Context/ConfigContext";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormRegister from "../FormRegister/FormRegister";
const FormRegisterContact = () => {
  
    const dataConfig = useContext(ConfigContext);
    const configs = dataConfig?.config
    const  hotlinevalue = configs.hotline
    const urlFacebook = configs.url_fb_sdc
    const  zalovalue = configs.zalo
    const email = configs.email_address
    const title_connect_social = configs.title_connect_social
    const title_advise_qa = configs.title_advise_qa
    const descriptionAdvise = configs.description_advise_phone

  return (
    <section>
      <div className="form-contact">
        <Container>
          <Row className="p-0">
            <Col lg="6" className="p-0 form-contact-register">
              <FormRegister />
            </Col>
            <Col lg="6">

               <div className='ask-consultant'>
               <div className='ask-title'><h2>{`${title_advise_qa}`}</h2></div>
             <div className='ask-content'>
               <div className='ask-img'>
               <img src={tu_van_vien} alt="contact-img"/>
               </div>
               <div className='ask-info'>
               <p className="text-description">{descriptionAdvise}</p>
               <p className='ask-tell'><Link to={`tel: ${hotlinevalue}`}> <i className="fa-solid fa-phone" ></i>{hotlinevalue}</Link></p>
               </div>
               <div className='ask-contact'>
               <h4>{`${title_connect_social}`}</h4>
                <ul className='show-contact'>
                    <li><Link to={urlFacebook} target='_blank'><img src={face_contact} alt="facebookx" className='icon-contact'/></Link></li>
                    <li><Link to={`https://zalo.me/${zalovalue}`} target='_blank'><img src={zalo_contact} alt="zalo" className='icon-contact'/></Link></li>
                    <li><Link to={`mailto:${email}`}><img src={gmail_contact} alt="gmail_contact" className='icon-contact'/></Link></li>
                </ul>
               </div>
            </div>
             </div>

            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default FormRegisterContact;
