import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const FeatureContext = createContext({});

export const FeatureProvider = ({ children }) => {
  const [dataFeature, setdataFeature] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/news?per_page=6&feature=1`).then((response) => {
      setdataFeature(response.data.data.data);
    });
  }, []);

  return <FeatureContext.Provider value={{ dataFeature }}>{children}</FeatureContext.Provider>;
};
