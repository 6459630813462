import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import { Col, Container, Row } from 'react-bootstrap';
import FormRegister from '../../components/UI/FormRegister/FormRegister';
import '../../styles/Lookup/Lookup.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import BoxPartners from '../Home/BoxPartners'
import { ConfigContext } from '../../Context/ConfigContext'
import contactImg from "../../assets/image/detailCouse/student.png"
import face_contact from '../../assets/image/IconsFloatingButton/Facebook-icon.png'
import zalo_contact from "../../assets/image/IconsFloatingButton/zalo-icon.png"
import gmail_contact from "../../assets/image/IconsFloatingButton/Gmail-logo.png"
import moment from 'moment';
import { Helmet } from 'react-helmet';
import FormRegisterContact from '../../components/UI/FormRegisterContact/FormRegisterContact';

const LookupDiplomas = () => {
  const dataConfig = useContext(ConfigContext)
  const configs = dataConfig?.config
  const titleLookup = configs.lookup_diplomas
  const hotlinevalue = configs.hotline
  const urlFacebook = configs.url_fb_sdc
  const email = configs.email_address
  const [dataLookupPoint, setDataLookupPoint] = useState([])
  const [showList, setShowList] = useState(false)
  const [isAdvanced, setIsAdvanced] = useState()
  const { register, handleSubmit, formState: { errors } } = useForm()

  const onHandleSubmit = (e) => {
   
    const type = e.type
    const name = e.name
    const date = e.date
    const key = e.key
    const value = e.value
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/lookup/diplomas?type=${type}&name=${name}&ngaysinh=${date}&key=${key}&value=${value}`)
      .then((response) => {
        setDataLookupPoint(response.data.data);
        setShowList(true)
      })
  }
  const dateInput = new Date()
  const dateInputMax = moment(dateInput.toISOString()).utc().format('YYYY-MM-DD')

  return (
    <div className='box-lookup-points'>
      <Header />
      <Helmet>
        <title>{titleLookup}</title>
      </Helmet>
      <main>
        <div className='breadcrumb-wrap'>
          <div className='container'>
            <ul className='breadcrumb justify-content-center'>
              <li className='breadcrumb-item'>
                <Link to='/'>Trang chủ</Link>
              </li>
              <li className='breadcrumb-item active'>Tra cứu văn bằng, chứng chỉ</li>
            </ul>
          </div>
        </div>
        <section className='section-lookup-content'>
          <div className='container'>
            <div className='section-title'>
              <div className='title text-violet'>
                <h2>
                  <span>{titleLookup}</span>
                </h2>
              </div>
            </div>
            <div className='section-content'>
              <div className='lookup-information'>

                <form onSubmit={handleSubmit(onHandleSubmit)}>
                  <div className="form-row align-items-center d-flex justify-content-center">
                    <div className="col-lg-3 my-1 ">
                      <label htmlFor="type" >Chọn chứng chỉ:
                        <i className='scv-2'>(*)</i>
                      </label>
                      <div className='input-group'>
                        <select
                          className={errors?.type?.type === 'required' ? 'error form-control' : 'form-control'}
                          {...register('type', { required: true })}
                          name="type"
                        >
                          <option value=''>Chọn chứng chỉ</option>
                          <option value='1'>Chứng chỉ CNTT cơ bản</option>
                          <option value='2'>Chứng chỉ CNTT nâng cao</option>

                        </select>
                      </div>

                    </div>
                    <div className="col-lg-3 my-1">
                      <label htmlFor="name" >Chọn thông tin tra cứu:</label>
                      <div className='input-group'>
                        <select
                          className={'form-control'}
                          {...register('key')}
                          name="key"
                        >
                          <option value=''>Chọn thông tin tra cứu</option>
                          <option value='1'>Số hiệu CC</option>
                          <option value='2'>Số CMND/CCCD</option>
                          <option value='3'>Số vào sổ</option>
                          <option value='4'>Khóa thi</option>
                          <option value='5'>Quyết định</option>

                        </select>
                      </div>

                    </div>

                    <div className="col-lg-3 my-1">
                      <label htmlFor="name" >Nhập từ khóa cần tìm:</label>
                      <div className='input-group'>
                        <input
                          name="name"
                          type="text"
                          className='form-control'
                          id="inlineFormInputGroupUsername"
                          placeholder='Nhập từ khóa cần tìm...'
                          {...register('value')}
                        />
                      </div>

                    </div>

                  </div>
                  <div className="form-row justify-content-center align-items-center d-flex">

                    <div className="col-lg-3 my-1">
                      <label htmlFor="name" >Nhập Họ và tên:
                        <i className='scv-2'>(*)</i>
                      </label>
                      <div className='input-group'>
                        <input
                          name="name"
                          type="text"
                          className={errors?.name?.type === 'required' ? 'error form-control' : 'form-control'}
                          id="inlineFormInputGroupUsername"
                          placeholder='Nhập họ và tên...'
                          {...register('name', { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 my-1">
                      <label htmlFor="trip-start">Nhập ngày sinh:
                        <i className='scv-2'>(*)</i>
                      </label>
                      <div className='input-group'>
                        <input type="date" id="start" name="trip-start"
                          min="1990-01-01"
                          max={dateInputMax}
                          className={errors?.date?.type === 'required' ? 'error form-control' : 'form-control'}
                          {...register('date', { required: true })}
                        />
                      </div>
                    </div>



                    <div className="col-lg-3 my-1  ">
                      <div className='input-group'>

                        <button type="submit" className="btn btn-primary ">Tra cứu</button>
                      </div>
                    </div>
                  </div>
                </form>

                {Object.keys(errors).length !== 0 && (
                  <ul className='error-container'>
                    {errors.type?.type === 'required' && <li>Vui lòng nhập Tên chứng chỉ</li>}
                    {errors.name?.type === 'required' && <li>Vui lòng nhập Họ và tên</li>}
                    {errors.date?.type === 'required' && <li>Vui lòng nhập Ngày sinh</li>}

                  </ul>
                )}

                <section className={showList ? 'list-view d-block' : 'd-none list-view'}  >
                  {isAdvanced == 1 ?
                    <table className='admindata table-bordered'>
                      <thead>
                        <tr>
                          <th>STT</th>
                          <th>Họ và tên</th>
                          <th>Ngày sinh</th>
                          <th>Số hiệu CC	</th>
                          <th>Điểm lý thuyết</th>
                          <th>Điểm thực hành</th>
                          <th>Số vào sổ</th>
                          <th>Khóa thi ngày</th>
                          <th>QĐ thành lập HĐT</th>
                          <th>QĐ công nhận TN</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLookupPoint?.length !== 0 ? dataLookupPoint?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td style={{ textAlign: 'left' }}>{data.fullname}</td>
                            <td>{data.birthday}</td>
                            <td>{data.certificate_no}</td>
                            <td>{data.lythuyet}</td>
                            <td>{data.thuchanh}</td>
                            <td>{data.date_entered}</td>
                            <td>{data.day_exam}</td>
                            <td>{data.decide_number}</td>
                            <td>{data.decide_cn}</td>
                          </tr>
                        ))
                          : <tr >
                            <td colSpan='10'>Không có dữ liệu</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                    :
                    <table className='admindata table-bordered'>
                      <thead>
                        <tr>
                          <th rowSpan={2}>STT</th>
                          <th rowSpan={2}>Số hiệu CC	</th>
                          <th rowSpan={2}>Họ và tên</th>
                          <th rowSpan={2}>Ngày sinh</th>
                         
                          <th colSpan={2}>Môn 1</th>
                          <th colSpan={2}>Môn 2</th>
                          <th colSpan={2}>Môn 3</th>
                          <th rowSpan={2}>Số vào sổ</th>
                          <th rowSpan={2}>Khóa thi ngày</th>
                          <th rowSpan={2}>QĐ thành lập HĐT</th>
                          <th rowSpan={2}>QĐ công nhận TN</th>
                        </tr>
                        <tr>
                          <th>Điểm LT</th>
                          <th>Điểm TH</th>
                          <th>Điểm LT</th>
                          <th>Điểm TH</th>
                          <th>Điểm LT</th>
                          <th>Điểm TH</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLookupPoint?.length !== 0 ? dataLookupPoint?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.certificate_no}</td>
                            <td style={{ textAlign: 'left' }}>{data.fullname}</td>
                            <td>{data.birthday}</td>
                            
                            <td>{data.mon1.lythuyet}</td>
                            <td>{data.mon1.thuchanh}</td>
                            <td>{data.mon2.lythuyet}</td>
                            <td>{data.mon2.thuchanh}</td>
                            <td>{data.mon3.lythuyet}</td>
                            <td>{data.mon3.thuchanh}</td>
                            <td>{data.date_entered}</td>
                            <td>{data.day_exam}</td>
                            <td>{data.decide_number}</td>
                            <td>{data.decide_cn}</td>
                          </tr>
                        ))
                          : <tr >
                            <td colSpan='10'>Không có dữ liệu</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  }
                </section>
              </div>
            </div>
          </div>

        </section>

      </main>
      
      <FormRegisterContact />
      <BoxPartners />
      <Footer />
    </div>
  )
}

export default LookupDiplomas