import React, { useContext } from "react";
import "../styles/Hotline.css";
import { ConfigContext } from "../Context/ConfigContext";

const Hotline = () => {
  const dataConfig = useContext(ConfigContext)
  const configs = dataConfig.config
  const  hotlinevalue =configs?.hotline

  return (
    <div className="hotline">
      <a href={`tel: ${hotlinevalue}`}>
        <i className="fa fa-phone"></i>
        <span>{hotlinevalue}</span>
      </a>
    </div>
  );
};

export default Hotline;
