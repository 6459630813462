import axios from 'axios'
import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import '../../styles/ReadMorePage/ReadMorePage.css'
import moment from 'moment/moment';
import { ConfigContext } from '../../Context/ConfigContext';
import { CategoriesContext } from '../../Context/CategoriesContext';
import { Helmet } from 'react-helmet';

const ReadMore = () => {
    const dataConfig = useContext(ConfigContext)
    const configs = dataConfig.config
    const titleBlog = configs?.title_blog
    const dataCategories = useContext(CategoriesContext)
    const categories = dataCategories.categories
    const [news, setNews] = useState([])
    const [lastPageNews, setLastPageNews] = useState(0)
    const nameParam = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    //sroll to ref
    const scrollNew = useRef();
    const scrollHanler = (elmRef) => {
        window.scrollTo({ top: elmRef.current.offsetTop, behavior: "smooth" });
    }
    const newsName = nameParam.name
    const page = searchParams.get("page");
    const pageidNews = Number(page)
    const [bannerCategory, setBannerCategory] = useState([])


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/news?slug_category=${newsName}&page=${pageidNews}&per_page=6`)
            .then(response => {
                setNews(response.data.data.data)
                setLastPageNews(response.data.data.last_page)
            })
        setBannerCategory(categories?.find(c => c.slug === newsName)?.image_url)
    }, [newsName, pageidNews])
    const prevPage = () => pageidNews - 1;
    const NextPage = () => pageidNews + 1;
    const pageNumbersNews = [];
    for (let i = 1; i <= lastPageNews; i++) {
        pageNumbersNews.push(i)
    }

    return (
        <div>
            <Header />
            <Helmet>
                <title>{titleBlog}</title>
            </Helmet>
            <main className='main-read-more-page'>
                <section className='banner no-padding'>
                    <div className='inner-header-title'>
                        <img src={bannerCategory} className='img-fluid' />
                    </div>
                </section>
            </main>


            <section id='content-tech-blog'>
                <div className='container'>
                    <ul className='box-breadcrumbs' ref={scrollNew}>
                        <li className='item-breadcrumb'>
                            <Link to='/'>Trang chủ</Link>
                        </li>
                        <li className='item-breadcrumb'>
                            <span>{titleBlog}</span>
                        </li>
                    </ul>
                </div>
                <div className='box-content-blog tech-blog'>
                    <div className='container'>
                        <div className='header-content'>
                            <h5 className='title-content m-0'>{titleBlog}</h5>
                            <p className='descript-title'>Cùng SDC đón đầu công nghệ - mở lối tương lai</p>
                        </div>
                        <div className='group-content-blog'>
                            <ul className='nav nav-pills mb-3 mt-3 mx-auto' id='pills-tab'>
                                {categories && categories.map(category => (
                                    
                                    <li
                                        className="nav-item"
                                        key={category.id}
                                        // onClick={() => setType(category.id)}
                                        onClick={() => setBannerCategory(category.image_url)}
                                    >
                                        <Link to={`/tin-tuc-su-kien/${category.slug}?page=${pageidNews}`} className={newsName === category.slug ? "nav-link active" : "nav-link "} >{category.title}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='box-content-blog-detail bg-section' >
                    <div className='container'>
                        <div className='box-more-blog'>
                            <div className='row'>
                                {news && news.map(item => (
                                    <div className='col-lg-4 col-md-6  mb-5' key={item.id}>
                                        <div className='item box-shadow-item'>
                                            <div className='row flex-column h-100'>
                                                <Link to={`/chi-tiet-bai-viet/${item.slug}`}>
                                                    <div className='col-md-12 col-xs-12 img-log'>
                                                        <img src={item.image_urls} style={{ width: '100%' }} />
                                                    </div>
                                                </Link>

                                                <div className='col-md-12 col-xs-12 flex-auto'>
                                                    <div className='small-content-blog d-flex flex-column h-100  '>
                                                        <div className='content-small flex-auto'>
                                                            <Link to={`/chi-tiet-bai-viet/${item.slug}`}>
                                                                <h4 className='my-3'>{item.title}</h4>
                                                            </Link>

                                                            <span className='date-blog'>{moment(item.created_at).format('DD/MM/YYYY')}</span>
                                                            <div className='des__sapo'>
                                                                <p>
                                                                    <strong>{item.summary}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='read-more-blog'>
                                                            <Link to={`/chi-tiet-bai-viet/${item.slug}`}>

                                                                <p>
                                                                    Xem thêm
                                                                    <i className='fa fa-angle-right'></i>
                                                                </p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <nav>
                        <div className='pagination justify-content-center'>
                            {
                                pageidNews !== 1 && (
                                    <li onClick={() => scrollHanler(scrollNew)}>
                                        <Link to={`/tin-tuc-su-kien/${newsName}?page=${pageidNews - 1}`}>
                                            <button
                                                className="button-page"
                                                style={{ cursor: "pointer" }}
                                                type="primary"
                                                onClick={() => prevPage()}>
                                                <i className="fa-solid fa-angles-left"></i>
                                            </button>
                                        </Link>
                                    </li>
                                )
                            }
                            {pageNumbersNews.map((num) => (
                                <li className='page-item' key={num} onClick={() => scrollHanler(scrollNew)}>

                                    <Link to={`/tin-tuc-su-kien/${newsName}?page=${num}`}
                                        className={pageidNews === num ? "number-page active-button" : "number-page"}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {num}
                                    </Link>
                                </li>
                            ))
                            }
                            {
                                pageNumbersNews.length !== pageidNews && (
                                    <li onClick={() => scrollHanler(scrollNew)}>
                                        <Link to={`/tin-tuc-su-kien/${newsName}?page=${pageidNews + 1}`}>
                                            <button
                                                style={{ cursor: "pointer" }}
                                                className="button-page"
                                                type="primary"
                                                onClick={() => NextPage()}>
                                                <i className="fa-solid fa-angles-right"></i>
                                            </button></Link>
                                    </li>
                                )
                            }
                        </div>
                    </nav>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ReadMore