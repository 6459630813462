import React, { useContext, useRef, useEffect, useState } from "react";
import "./CardProject.css";
import { ConfigContext } from "../../../Context/ConfigContext";
const CardProject = (props) => {
  const closeFrom = useRef(null);
  const [dataLink, setDataLink] = useState("");
  const addVideo = () => {
    closeFrom.current.classList.add("show__video");
    document.body.classList.add("hiddenScroll");
  };
  const removeVideo = () => {
    closeFrom.current.classList.remove("show__video");
    document.body.classList.remove("hiddenScroll");
    setDataLink("");
  };
  const dataConfig = useContext(ConfigContext);
  const configs = dataConfig.config;

  const title = {
    titleCard: configs?.project_student_title,
    titleSystem: configs?.products_description,
  };

  useEffect(() => {
    document.addEventListener("keydown", function (e) {
      if (e.key === "Escape" && closeFrom.current.classList.contains("show__video")) {
        removeVideo();
      }
    });
  }, []);
  return (
    <div>
      <div className="section__title-product">
        <div className="title row justify-content-start text-violet text-center">
          <div className="col-lg-12 mb-3">
            <h2>
              <span>{title.titleCard}</span>
            </h2>
          </div>
          <div className="col-lg-12 d-flex align-items-center justify-content-center">
            <div className="title-caps">
              <p>{title.titleSystem}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="section__content-product">
        <div className="row">
          {props.data &&
            props.data.map((product) => (
              <div className="col-md-6 col-lg-4 " key={product.id}>
                <div className="st-product-item" onClick={() => setDataLink(product.link)}>
                  <img src={product.image_url} className="img-fluid lazy" alt="" />
                  <div className="st-product-item-overlay" onClick={addVideo}>
                    <span className="js-video-button" data-video-id="bbjLCHI82bY"></span>
                  </div>
                  <div className="st-product-item-caps">
                    <h3 title={product.title} onClick={addVideo}>
                      {product.title}
                    </h3>
                    <p>{product.description}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="modal-video" ref={closeFrom} onClick={removeVideo}>
        <div className="modal-video-project">
          <div className="modal-video-wrap">
            <div className="modal-video-ytb">
              <div
                dangerouslySetInnerHTML={{
                  __html: dataLink,
                }}
              ></div>
              <div className="modal-close" onClick={removeVideo}>
                <button className="modal-close-btn">
                  <i className="fa-sharp fa-regular fa-circle-xmark"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardProject;
