import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import '../../styles/pageCourse/Course.css'
import { Helmet } from 'react-helmet'
import FormRegister from '../../components/UI/FormRegister/FormRegister'
import { useContext } from 'react'
import { ConfigContext } from '../../Context/ConfigContext'
const Course = () => {
    const [titleCourses, setTitleCourses] = useState('')
    const { slug } = useParams()
    const [courses, setCourses] = useState([])
    const [coursesCategory, setCoursesCategory] = useState()
    const closeFrom = useRef(null);
    const [bannerCourses, setBannerCourses] = useState([])
    const idCourseCategory = coursesCategory?.find(c => c.slug === slug).id
    const dataConfig = useContext(ConfigContext)
    const configs = dataConfig.config
    const timeRequest = configs.timeout_show_modal;
    useEffect(() => {
        document.addEventListener('keydown' ,function(e){
            if(e.key === 'Escape' && closeFrom.current.classList.contains('show__form')){
             closeFrom.current.classList.remove("show__form");
             document.body.classList.remove("hiddenScroll");
            }
         })
        const timer = setTimeout(() => {
            closeFrom.current.classList.toggle("show__form");
            document.body.classList.toggle("hiddenScroll");
          }, timeRequest);
    },[])
    const toggleMenu = () => {
        closeFrom.current.classList.toggle("show__form");
        document.body.classList.toggle("hiddenScroll");
      };
      
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/course-categories`)
            .then(response => {
                setCoursesCategory(response.data.data)
              
                setTitleCourses(coursesCategory?.find(c => c.id === idCourseCategory)?.title);
                setBannerCourses(coursesCategory?.find(c => c.id === idCourseCategory).image_url);
            })
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/courses`)
            .then(response => {
                setCourses(response.data.data?.filter(c => c.course_category_id === idCourseCategory));
            })
    }, [idCourseCategory])  
    return (
        <div className='course'>
            <Helmet>
                <title>{titleCourses}</title>
                
            </Helmet>
            <Header />

            <main>
                <section className='banner no-padding'>
                    <div className='inner-header-title'>

                        <img src={bannerCourses} className='img-fluid' />
                    </div>
                </section>

                <div className='breadcrumb-wrap'>
                    <div className='container'>
                        <ul className='breadcrumb justify-content-center'>
                            <li className='breadcrumb-item'>
                                <Link to='/'>Trang chủ</Link>
                            </li>
                            <li className='breadcrumb-item active'>Khóa học</li>
                        </ul>
                    </div>
                </div>
                <section className='section-course-content'>
                    <div className='container'>
                        <div className='section-title'>
                            <div className='title text-violet'>
                                <h2>
                                    <span>{titleCourses}</span>
                                </h2>
                            </div>
                        </div>

                        <div className='section-content'>
                            <div className='course-content'>
                                <div className='row'>
                                    {courses?.map((course, index) => (
                                        <div className='col-lg-4 col-md-6' key={index}>
                                            <div className='course-item box-shadow'>
                                                <div className='course-item-img'>
                                                    <Link to={`/chi-tiet-khoa-hoc/${course.slug}/${course.id}`}>
                                                        <img src={course.image_url} title={course.title} />
                                                    </Link>
                                                </div>
                                                <div className='course-item-title'>
                                                    <h3 title={course.title}>
                                                
                                                        <Link to={`/chi-tiet-khoa-hoc/${course.slug}/${course.id}`}>{course.title}</Link>
                                                    </h3>
                                                    <p>{course.time}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />

            <div className="form-container" ref={closeFrom}>
            <div className="form-register-advise">
                <FormRegister/>
                <div className="form__close" onClick={toggleMenu}>
            <button className="form__close-btn">
              <i className="fa-sharp fa-regular fa-circle-xmark"></i>
            </button>
          </div>
            </div>
            </div>
        </div>
    )
}

export default Course