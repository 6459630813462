import React, { useContext, useEffect, useState } from "react";
import "../../styles/HomePage/BoxProducts.css";
import axios from "axios";
import { Link } from "react-router-dom";
import CardProject from "../../components/UI/CardProject/CardProject";
import { ConfigContext } from "../../Context/ConfigContext";

const Products = () => {
  const [dataproducts, SetDataproducts] = useState([]);
  const [page1, SetPage] = useState(1);
  const dataConfig = useContext(ConfigContext);
  const configs = dataConfig.config;
  const slugNews = configs?.slug_project;

  //API project-students
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/project-students?page=${page1}&per_page=6`)
      .then((res) => {
        SetPage(res.data.data.current_page);
        SetDataproducts(res.data.data.data);
      });
  }, []);
  return (
    <section className="product">
      <div className="container">
        <CardProject data={dataproducts} />
        <div className="text-center">
          <Link
            to={`/du-an-hoc-vien/san-pham?page=1`}
            className="link-read-more load-more but-primary but-lg  "
          >
            Xem thêm
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Products;
