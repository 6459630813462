import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const Marquees = () => {
  const [marquees, setMarquees] = useState([]);

  useEffect(() => {
    // api chữ chạy
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/news?sortfield=id&sorttype=DESC`).then((response) => {
      setMarquees(response.data.data.data);
    });
  }, []);
  return (
    <>
      {marquees.length > 0 && (
        <div className="marquee-wrapper">
          <marquee
            onMouseOver={() => {
              document.querySelector("marquee")?.stop();
            }}
            onMouseOut={() => {
              document.querySelector("marquee")?.start();
            }}
          >
            {marquees?.map((item, index) => (
              <span key={index}>
                <Link to={`/chi-tiet-bai-viet/${item.slug}`}>
                  <span className="marquee-item">{`${item.title}`}</span>
                </Link>
                <span className="fs-4">|</span>
              </span>
            ))}
          </marquee>
        </div>
      )}
    </>
  );
};

export default Marquees;
