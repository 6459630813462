import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import DetailNews from "../pages/PageDetailNews/DetailNews";
import Home from "../pages/Home/Home";
import NotFound from "../pages/NotFound";
import ReadMore from "../pages/PageReadMore/ReadMore";
import Introduce from "../pages/IntroduceSDC/Introduce";
import ReadMoreProject from "../pages/ReadMoreProjects/ReadMoreProject";
import ReadMoreSigned from "../pages/ReadMoreSigned/ReadMoreSigned";
import Course from "../pages/Course/Course";
import DetailCourse from "../pages/DetailCourse/DetailCourse";
import LookupPoints from "../pages/LookupPoints/LookupPoints";
import LookupDiplomas from "../pages/LookupDiplomas/LookupDiplomas";
import PageContact from "../pages/PageContact/PageContact";
import KeyWord from "../pages/KeyWord/KeyWord";
import AllCourse from "../pages/AllCourse/AllCourse";
import ScheduleExam from "../pages/ScheduleExam/ScheduleExam";
import ListLectures from "../pages/ListLectures/ListLectures";
import ListStudent from "../pages/ListStudent/ListStudent";
import DetailLectures from "../pages/DetailLectures/DetailLectures";
import DetailStudents from "../pages/DetailStudents/DetailStudents";
import OpeningSchedule from "../pages/OpeningSchedule/OpeningSchedule";

const Routers = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/laravel-filemanager/*"></Route>
      <Route path="/trang-chu" element={<Home />}></Route>
      <Route path="/lich-thi" element={<ScheduleExam />}></Route>
      <Route path="/lich-khai-giang" element={<OpeningSchedule />}></Route>
      {/* <Route path='*' element={<NotFound />}></Route> */}
      <Route path="/chi-tiet-bai-viet/:slug" element={<DetailNews />}></Route>
      <Route path="/tin-tuc-su-kien/:name" element={<ReadMore />}></Route>
      <Route path="/result-keyword/:keyword" element={<KeyWord />}></Route>
      <Route path="/gioi-thieu" element={<Introduce />}></Route>
      <Route path="/du-an-hoc-vien/:slug" element={<ReadMoreProject />}></Route>
      <Route path="/khoa-hoc/:slug" element={<Course />}></Route>
      <Route path="/chi-tiet-khoa-hoc/:slug/:id" element={<DetailCourse />}></Route>
      <Route path="/tra-cuu-diem-thi" element={<LookupPoints />}></Route>
      <Route path="/tra-cuu-van-bang" element={<LookupDiplomas />}></Route>
      <Route path="/lien-he" element={<PageContact />}></Route>
      <Route path="/ky-ket-hop-tac/:slug" element={<ReadMoreSigned />}></Route>
      <Route path="/khoa-hoc" element={<AllCourse />}></Route>
      <Route path="/giang-vien-chuyen-gia" element={<ListLectures />}></Route>
      <Route path="/cuu-hoc-vien" element={<ListStudent />}></Route>
      <Route path="/chi-tiet-giang-vien/:slug" element={<DetailLectures />}></Route>
      <Route path="/chi-tiet-hoc-vien/:slug" element={<DetailStudents />}></Route>
    </Routes>
  );
};

export default Routers;
