import React, { useEffect, useState, useContext, useRef } from 'react'
import '../../styles/DetailNews/DetailNews.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import moment from 'moment/moment';
import { Link, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import Hotline from '../Hotline';
import { ConfigContext } from '../../Context/ConfigContext';
import { SettingContext } from '../../Context/SettingContext';
import SideBar from '../SideBar/SideBar';
import { Helmet } from 'react-helmet';
import { CategoriesContext } from '../../Context/CategoriesContext';
import CommentsFB from '../../components/CommentsFB/CommentsFB'
const DetailNews = () => {
    const dataConfig = useContext(ConfigContext)
    const dataSettings = useContext(SettingContext)
    const slugCategories = useContext(CategoriesContext)
    const configs = dataConfig.config
    const categories_slug = slugCategories.categories[0]?.slug
    const titleBlog = configs?.title_blog
    let location = useLocation();
    let { slug } = useParams()
    const [detailNews, setDetailNews] = useState({})
    const [keyWordsDetail, setKeyWordsDetail] = useState([])
    // call API detailNews
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/news/detail/${slug}/`)
            .then(response => {
                setDetailNews(response.data.data)
                setKeyWordsDetail(response.data.data.keyword)
              
            })
    }, [location])


    return (
        <>
            <Header />
            <Helmet>
                <title>{detailNews?.title}</title>

            </Helmet>
            <div className='breadcrumb-wrap'>
                <div className='container'>
                    <ul className='breadcrumb'>
                        <li className='breadcrumb-item'>
                            <Link to='/'>Trang chủ</Link>
                        </li>
                        <li className='breadcrumb-item'>
                            <Link to={`/tin-tuc-su-kien/${categories_slug}?page=1`}>{titleBlog}</Link>
                        </li>
                        <li className='breadcrumb-item active'>Bài viết</li>
                    </ul>
                </div>
            </div>
            <section className='section-details'>
                <div className='section-content'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <div className='details-post'>
                                    <h2 className='details-post-title'>{detailNews?.title}</h2>
                                    <div className='details-post-time'>
                                        <span>
                                            <i className="far fa-clock"></i>
                                            {moment(detailNews?.created_at).format('DD/MM/YYYY')}
                                        </span>
                                        <span>
                                            <i className="fas fa-user-circle"></i>
                                            {detailNews?.users?.name}
                                        </span>
                                    </div>
                                    <div className='row align-items-center'>
                                        <strong>Từ khóa:</strong>
                                        <div className='keyword '>
                                            {keyWordsDetail?.map((keyWord, index) => (
                                                <Link to={`/result-keyword/${keyWord}?page=1`} key={index}>
                                                    <span className='keyword-item'>#{keyWord}</span>
                                                </Link>

                                            ))}
                                        </div>
                                    </div>

                                    <hr />
                                    <div className='details-post-description'>
                                        <p className='page_speed_248973596'>
                                            <strong>{detailNews?.summary}</strong>
                                        </p>
                                    </div>
                                    <div className='details-post-content'>
                                        <p>
                                            <img src={detailNews?.image_urls} alt={detailNews?.image} style={{ width: '100%', height: '100%' }} />
                                        </p>

                                        <p dangerouslySetInnerHTML={{ __html: detailNews?.content }}></p>
                                        <p>&nbsp;</p>
                                        
                                        <CommentsFB dataHref={detailNews?.slug}/>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>

                                <SideBar />
                            </div>
                        </div>
                    </div>
                </div>

            </section >
            <Hotline />
            <Footer />
        </>
    )
}

export default DetailNews