import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import bannerCourse from "../../assets/image/banner/banner-khoa-hoc.png";
import axios from "axios";
const AllCourse = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    // api tất cả khóa học
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/courses/overview-courses`).then((response) => {
      setData(response.data.data);
      console.log(response.data.data);
    });
  }, []);
  return (
    <div>
      <Header />
      <main>
        <section className="banner no-padding">
          <div className="inner-header-title">
            <img src={bannerCourse} className="img-fluid" />
          </div>
        </section>
        <section className="section-course-content">
          <div className="container">
            <div className="section-title">
              <div className="title text-violet">
                <h2>
                  <span>Các khóa học</span>
                </h2>
              </div>
            </div>

            <div className="section-course-list">
              {data?.map((dataItem, index) => (
                <div className="course-list-wrap">
                  <div className="row">
                    <h4 className="title-coure">
                      <Link to={`/khoa-hoc/${dataItem.slug}`}>{dataItem.title} </Link>
                    </h4>
                    {dataItem.courses?.map((course, index) => (
                      <div className="col-lg-4 col-md-6" key={index}>
                        <div className="course-item box-shadow">
                          <div className="course-item-img">
                            <Link to={`/chi-tiet-khoa-hoc/${course.slug}/${course.id}`}>
                              <img src={course.image_url} title={course.title} alt="course_img" />
                            </Link>
                          </div>
                          <div className="course-item-title">
                            <h3 title={course.title}>
                              <Link to={`/chi-tiet-khoa-hoc/${course.slug}/${course.id}`}>{course.title}</Link>
                            </h3>
                            <p>{course.time}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AllCourse;
