import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Hotline from "../Hotline";
import { ConfigContext } from "../../Context/ConfigContext";
import { FeatureContext } from "../../Context/FeatureContext";
const SideBar = () => {
  const [keyWords, setKeyWords] = useState([]);
  const [keyWordsDetail, setKeyWordsDetail] = useState([]);
  const [listNews, setListNews] = useState([]);
  // call API features news
  const dataFeature = useContext(FeatureContext);
  const featuresNews = dataFeature?.dataFeature;
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/news?per_page=6`).then((response) => {
      setListNews(response.data.data.data);
      setKeyWords(response.data.keywords);
    });
  }, []);
  return (
    <div>
      <div className="sidebar">
        <div className="widget">
          <div className="widget-title">
            <h3>
              <span>Tin mới</span>
            </h3>
          </div>
          <div className="widget-content">
            <ul>
              {listNews &&
                listNews.map((item, index) => (
                  <Link to={`/chi-tiet-bai-viet/${item.slug}/`} key={index}>
                    <li className="item-post-sidebar">
                      <div className="row w-100">
                        <div className="col-5">
                          <img src={item.image_urls} />
                        </div>
                        <div className="col-7">
                          <h3>{item.title}</h3>
                        </div>
                      </div>
                    </li>
                  </Link>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="sidebar">
        <div className="widget">
          <div className="widget-title">
            <h3>Tin nổi bật</h3>
          </div>
          <div className="widget-content">
            <ul>
              {featuresNews &&
                featuresNews.map((item, index) => (
                  <Link to={`/chi-tiet-bai-viet/${item.slug}/`} key={index}>
                    <li className="item-post-sidebar">
                      <div className="row">
                        <div className="col-5">
                          <img src={item.image_urls} alt="imgDetailnews" />
                        </div>
                        <div className="col-7">
                          <h3>{item.title}</h3>
                        </div>
                      </div>
                    </li>
                  </Link>
                ))}
            </ul>
            <div style={{ padding: "0 45px" }}>
              <strong>Từ khóa:</strong>
              <div className="keyword row" style={{ marginBottom: "14px" }}>
                {keyWords?.map((keyWord, index) => (
                  <Link to={`/result-keyword/${keyWord}?page=1`} key={index}>
                    <span className="keyword-item">#{keyWord}</span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
