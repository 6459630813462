import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) => {
    const [config, setConfig] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/configs`)
            .then(response => {
                setConfig(response.data.data);
            })
    }, [])

    return <ConfigContext.Provider value={{config}}>
        {children}
    </ConfigContext.Provider>
}

