import React, { useContext, useEffect, useState } from 'react'
// import '../../styles/HomePage/BoxLecturers.css';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import bannerLectures from '../../assets/image/banner/giang-vien.jpg'
import { ConfigContext } from '../../Context/ConfigContext'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
const ListLectures = () => {
  const dataConfig = useContext(ConfigContext)
  const configs = dataConfig?.config
  const description = configs?.lectures_description?.slice(25)
  const strongDescription = configs?.lectures_description?.slice(0, 24)
  const titleLecturers = configs?.title_teaching_staff
  const [teachers, setTeachers] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/teachers`)
      .then((response) => {
        setTeachers(response.data.data);
      })
  }, [])
  return (
    <div>
      <Header />
      <Helmet>
        <title>{titleLecturers}</title>
        
      </Helmet>
      <main>
        <section className='banner no-padding'>
          <div className='inner-header-title'>

            <img src={bannerLectures} className='img-fluid' />
          </div>
        </section>
        <section className='section-lectures-content'>
          <div className='container'>
            <div className='section-title m-0'>
              <div className='title text-violet m-0 '>
                <h2>
                  <span>{titleLecturers}</span>
                </h2>
              </div>
            </div>
            <div className='section-description'>
              <div className='row justify-content-center text-center'>
                <div className='col-lg-11 col-md-8'>
                  <p>
                    <strong>{strongDescription}</strong>&nbsp;
                    {description}
                  </p>
                </div>
              </div>
            </div>
            <section>
              <div className='row team p-0'>
                {teachers?.map((teacher, index) => (
                  <div className='col-lg-4 col-md-6 abc' key={index}>
                    <div className='box-shadow'>
                    <div className='box-img'>
                      <Link to={`/chi-tiet-giang-vien/${teacher.slug}`}>
                        <img src={teacher.avatar_urls} />
                      </Link>
                    </div>
                    <div className='box-text text-center'>
                      <div className='box-text-inner'>
                        <h4>
                          <Link to={`/chi-tiet-giang-vien/${teacher.slug}`}>{teacher.fullname}</Link>
                        </h4>
                        <div className='fw-bold color-heading mb-3'>{teacher.profession}</div>
                        <div dangerouslySetInnerHTML={{ __html: teacher.description }} className='teacher-description fst-italic'></div>

                      </div>
                    </div>
                    </div>
                    
                  </div>
                ))}

              </div>

            </section>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}

export default ListLectures