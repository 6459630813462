import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../styles/OpeningSchedule/OpeningSchedule.css";
import { Helmet } from "react-helmet";
import moment from "moment/moment";
import { ConfigContext } from "../../Context/ConfigContext";
import BannerDetail from "../../components/UI/BannerDetail/BannerDetail";
import bannerTest from "../../assets/image/IntroduceSDC/BannerSDCIntroduce.png";
import { Link, NavLink, useParams, useSearchParams } from "react-router-dom";
import { CategoriesCoursesContext } from "../../Context/CategoriesCoursesContext";
import axios from "axios";
import logoFb from "../../assets/image/lich-khai-giang/icon-facebook.png";
import logoZalo from "../../assets/image/lich-khai-giang/zalo.jpg";
import { CourseContext } from "../../Context/CoursesContext";
import { FeatureContext } from "../../Context/FeatureContext";
import FormRegister from "../../components/UI/FormRegister/FormRegister";
const OpeningSchedule = () => {
  const dataCourse = useContext(CourseContext);
  const course = dataCourse?.course;
  const dataFeature = useContext(FeatureContext);
  const featuresNews = dataFeature?.dataFeature;
  const dataConfig = useContext(ConfigContext);
  const dataCategories = useContext(CategoriesCoursesContext)?.categories;
  const configs = dataConfig?.config;
  const title = configs?.title_opening_schedule;
  const urlFb = configs?.url_fb_sdc;
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("khoa-hoc");
  const [dataSchedules, setDataSchedules] = useState([]);
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/opening-schedules`).then((response) => {
      setDataSchedules(response.data.data);
      const tabsList = document.querySelector(".scrollable-tabs-container ul");
      const leftArrowContainer = document.querySelector(".scrollable-tabs-container .left-arrow");
      const rightArrowContainer = document.querySelector(".scrollable-tabs-container .right-arrow");
      const rightArrow = document.querySelector(".scrollable-tabs-container .right-arrow i");
      const leftArrow = document.querySelector(".scrollable-tabs-container .left-arrow i");
      const manageIcons = () => {
        if (tabsList.scrollLeft >= 20) {
          leftArrowContainer.classList.add("active");
        } else {
          leftArrowContainer.classList.remove("active");
        }

        let maxScrollValue = tabsList.scrollWidth - tabsList.clientWidth - 20;

        if (tabsList.scrollLeft >= maxScrollValue) {
          rightArrowContainer.classList.remove("active");
        } else {
          rightArrowContainer.classList.add("active");
        }
      };
      rightArrow.addEventListener("click", () => {
        tabsList.scrollLeft += 180;
        manageIcons();
      });

      leftArrow.addEventListener("click", () => {
        tabsList.scrollLeft -= 180;
        manageIcons();
      });
      tabsList.addEventListener("scroll", manageIcons);
      let dragging = false;

      const drag = (e) => {
        if (!dragging) return;
        tabsList.classList.add("dragging");
        tabsList.scrollLeft -= e.movementX;
      };

      tabsList.addEventListener("mousedown", () => {
        dragging = true;
      });

      tabsList.addEventListener("mousemove", drag);

      document.addEventListener("mouseup", () => {
        dragging = false;
        tabsList.classList.remove("dragging");
      });
    });
    
  }, []);
  const closeFrom = useRef(null);
  const toggleMenu = () => {
    closeFrom.current.classList.toggle("show__form");
    document.body.classList.toggle("hiddenScroll");
  };
  const courseFilter = dataSchedules.filter((courseSchedule) => courseSchedule.course_category.slug === tab);
  return (
    <div>
      <Header />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BannerDetail imgBanner={bannerTest} titleH2={title} />
      <section className="schedule-navbar ">
        <div className="container">
          <div className="row">
            <ul className="box-schedule">
              <li className="item-schedule">
                <Link to="/">Trang chủ</Link>
              </li>
              <li className="item-schedule">
                <span>{title}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="schedule-body mb-5 pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="scrollable-tabs-container">
                <div className="left-arrow">
                  <i class="fa-solid fa-angles-left"></i>
                </div>
                <ul className=""   >
                  {dataCategories?.map((item, index) => (
                    <li className="nav-item" key={index}  >
                      <Link to={`/lich-khai-giang?khoa-hoc=${item.slug}`} className={tab === item.slug ? " active " : ""}  >
                     {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className="right-arrow active">
                  <i class="fa-solid fa-angles-right"></i>
                </div>
              </div>
              <div>
                {courseFilter?.map((dataSchedule, index) => (
                  <div key={index} className="schedule-content">
                    <Link to="#" className="name-course">
                      {dataSchedule.courses?.title}
                    </Link>
                    <div className="div-tuition">
                      <strong>
                        Học phí:{
                          dataSchedule.preferential_tuition === null || '' ?  <span className="tuition">{dataSchedule.tuition.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} VNĐ</span> :  <strike className="tuition">{dataSchedule.tuition.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} VNĐ</strike>
                        }
                        {
                          dataSchedule.preferential_tuition === null || '' ? "" :  <span className="text-danger endow">&nbsp; -&nbsp; Ưu đãi:{" "}{dataSchedule.preferential_tuition?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} VNĐ</span>
                        }
                      </strong>
                    </div>
                    <div className="div-lectures">
                      <strong>Giảng viên: {dataSchedule.lecturers}</strong>
                    </div>
                    <div className="study-time">
                      <strong>Thời gian học: {dataSchedule.courses.time} </strong>
                    </div>

                    <table className="col-sm-12 table-schedule w-100">
                      <thead>
                        <tr>
                          <th>Tên lớp học</th>
                          <th>Khai giảng</th>
                          <th>Thời gian</th>
                          <th>Tư vấn</th>
                          <th>Đăng ký</th>
                        </tr>
                      </thead>
                      {dataSchedule.data.map((item, index) => (
                        <tbody key={index}>
                          <tr>
                            <td data-label="Tên lớp học">
                              <div>{item.name}</div>
                            </td>
                            <td className="text-center" data-label="Khai giảng">
                              {item.open_time}
                            </td>
                            <td data-label="Thời gian ">{item.study_time}</td>
                            <td data-label="Tư vấn">
                              <div>{item.counselors.name}</div>
                              <span>
                                <a href={`tel: ${item.counselors.phone}`}>
                                  <span>
                                    <i className="fa-solid fa-phone icon-phone"></i>
                                    {item.counselors.phone}
                                  </span>
                                </a>
                                <Link to={urlFb} target="_blank" title="facebook">
                                  <img src={logoFb} className="icon-fb" alt="logoFB" />
                                </Link>
                                <Link to={`https://zalo.me/${item.counselors.phone}`} target="_blank" title="zalo">
                                  <img src={logoZalo} className="icon-zalo" alt="logozalo" />
                                </Link>
                              </span>
                            </td>
                            <td className="text-center btn-register ">
                              <div>
                                <span className="button-register" onClick={toggleMenu}>
                                  Đăng ký
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="thematic-category">
                <h2 className="thematic-category-title">CÁC KHÓA HỌC</h2>
                <div className="thematic-menu">
                  <ul>
                    {course &&
                      course.map((courseItem) => (
                        <li key={courseItem.id}>
                          <Link to={`/chi-tiet-khoa-hoc/${courseItem.slug}/${courseItem.id}`}>
                            {" "}
                            <i className="fa-solid fa-angle-right"></i>
                            {courseItem.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>

              <div className="news-category">
                <h2 className="news-category-title">TIN TỨC NỔI BẬT</h2>
                <div className="news-category-menu">
                  <ul>
                    {featuresNews &&
                      featuresNews.map((item, index) => (
                        <li key={index}>
                          <i className="fa-solid fa-calendar-days"></i>
                          {item && moment(item?.created_at).format("DD/MM/YYYY")}
                          <div className="news-category-menu-title">
                            {" "}
                            <Link to={`/chi-tiet-bai-viet/${item.slug}/`} key={index}>
                              {item.title}
                            </Link>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="form-container" ref={closeFrom}>
            <div className="form-register-advise">
                <FormRegister/>
                <div className="form__close" onClick={toggleMenu}>
            <button className="form__close-btn">
              <i className="fa-sharp fa-regular fa-circle-xmark"></i>
            </button>
          </div>
            </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default OpeningSchedule;
