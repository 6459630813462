import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import "./FormRegister.css";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import { CourseContext } from "../../../Context/CoursesContext";

const FormRegister = () => {
  const [loading, setIsLoading] = useState(false);
  const [caps, setcaps] = useState(false);
  const [ip, setIp] = useState([]);
  const captcha = useRef(null);
  const dataCourse = useContext(CourseContext);
  const courseTitle = dataCourse.course;

  const options = courseTitle.map((option) => ({
    id: option.id,
    label: option.title,
    value: option.title,
  }));
  const defaultValues = {
    courseOptions: "",
  };
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors ,isDirty, isSubmitting,isValid  },
  } = useForm({
    defaultValues,
  });
  useEffect(() => {
    axios.get("https://geolocation-db.com/json/'").then((response) => {
      setIp(response.data.IPv4);
    });
  }, []);

  const onSubmit = (data) => {
    const singleValue = getValues("courseOptions");
    data.course_id = singleValue.id;

    data.ip_address = ip; // thêm địa chỉ IP
    delete data.courseOptions;
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/consultations/store`, data).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message);
        setIsLoading(false);
        reset();
        captcha.current.reset();
        setcaps(false);
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsLoading(false);
        captcha.current.reset();
        setcaps(false);
      }
    });
  };

  const onChange = () => {
    if (captcha.current.getValue()) {
      setcaps(true);
    }
  };
  let minOffset = 0,
    maxOffset = 48;
  let thisYear = new Date().getFullYear() - 5;
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears.map((x) => {
    return x;
  });

  return (
    <form className="subscribe-form" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="text-violet">ĐĂNG KÝ NHẬN TƯ VẤN MIỄN PHÍ</h3>

      <div className="form__Group">
        <label htmlFor="fullName" className="lableSubcribe">
          Họ tên<i className="scv-2">(*)</i>
        </label>
        <input
        autoComplete="nope" 
          type="text"
          name="fullName"
          className="form-control"
          id="fullName"
          placeholder="Họ tên"
          {...register("name", {
            required: true,
            minLength: 2,
            maxLength: 50,
          })}
        />
      </div>

      {Object.keys(errors).length !== 0 && (
        <div>
          {errors.name?.type === "required" && (
            <div className="errorsWarning">Vui lòng điền Họ tên của bạn</div>
          )}
          {(errors.name?.type === "minLength" || errors.name?.type === "maxLength") && (
            <div className="errorsWarning">Họ tên của bạn không hợp lệ vui lòng kiểm tra lại!</div>
          )}
        </div>
      )}
      <div className="form__Group">
        <label htmlFor="phone" className="lableSubcribe">
          Email<i className="scv-2">(*)</i>
        </label>
        <input
        autoComplete="nope" 
          type="email"
          name="email"
          className="form-control"
          id="email"
          placeholder="Email"
          {...register("email", {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          })}
        />
      </div>
      {Object.keys(errors).length !== 0 && (
        <div>
          {" "}
          {errors.email?.type === "required" && (
            <div className="errorsWarning">Vui lòng điền Email của bạn</div>
          )}
          {errors.email?.type === "pattern" && (
            <div className="errorsWarning">Email không hợp lệ vui lòng kiểm tra lại!</div>
          )}
        </div>
      )}

      <div className="form__Group">
        <label htmlFor="phone" className="lableSubcribe">
          Số điện thoại
          <i className="scv-2">(*)</i>
        </label>
        <input
        autoComplete="nope" 
          type="text"
          name="phone"
          className="form-control"
          id="phone"
          placeholder="Số điện thoại"
          onKeyPress={(event) => {
            if (!/[0-9+]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          {...register("phone", {
            required: true,
            minLength: 10,
            maxLength: 12,
            pattern: /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})/,
          })}
        />
      </div>
      {Object.keys(errors).length !== 0 && (
        <div>
          {errors.phone?.type === "required" && (
            <div className="errorsWarning">Vui lòng điền số điện thoại của bạn!</div>
          )}
          {(errors.phone?.type === "minLength" ||
            errors.phone?.type === "maxLength" ||
            errors.phone?.type === "pattern") && (
            <div className="errorsWarning">Số điện thoại không hợp lệ vui lòng kiểm tra lại!</div>
          )}
        </div>
      )}

      <div className="form__Group">
        <label htmlFor="address" className="lableSubcribe">
          Địa chỉ<i className="scv-2">(*)</i>
        </label>
        <input
        autoComplete="nope" 
          type="text"
          name="address"
          className="form-control"
          id="address"
          placeholder="Địa chỉ"
          {...register("address", {
            required: true,
            minLength: 2,
            maxLength: 100,
          })}
        />
      </div>
      {Object.keys(errors).length !== 0 && (
        <div>
          {errors.address?.type === "required" && (
            <div className="errorsWarning">Vui lòng điền địa chỉ của bạn</div>
          )}
          {(errors.address?.type === "minLength" || errors.address?.type === "maxLength") && (
            <div className="errorsWarning">Địa chỉ không hợp lệ vui lòng kiểm tra lại!</div>
          )}
        </div>
      )}

      <div className="form__Group">
        <label htmlFor="year" className="lableSubcribe">
          Năm sinh<i className="scv-2">(*)</i>
        </label>
        <select
          className="form-control-select"
          id="year"
          {...register("year_of_birth", {
            required: true,
          })}
        >
          <option className="lable_year" value="">
            Chọn năm sinh của bạn
          </option>
          {yearList &&
            yearList.map((yearOption, index) => (
              <option className="form-control" value={yearOption.id} key={index}>
                {yearOption}{" "}
              </option>
            ))}
        </select>
      </div>
      {Object.keys(errors).length !== 0 && (
        <div>
          {" "}
          {errors.year_of_birth?.type === "required" && (
            <div className="errorsWarning">Vui lòng chọn năm sinh của bạn</div>
          )}
        </div>
      )}

      <div className="form__Group">
        <label htmlFor="course" className="lableSubcribe">
          Khóa học<i className="scv-2">(*)</i>
        </label>
        <Controller
          name="courseOptions"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              placeholder="Chọn khóa học cần tư vấn"
              isClearable
              {...field}
              options={options}
            />
          )}
        />
        {errors.courseOptions && (
          <div className="errorsWarning">Vui lòng chọn khóa học cần tư vấn</div>
        )}
      </div>

      <div className="recaptcha form__Group">
        <ReCAPTCHA
          className="captcha"
          ref={captcha}
          sitekey="6LdZL1glAAAAABoJb4N7b0qtxDRp21n9UenQuuyx"
          onChange={onChange}
          onExpired={() => {
            setcaps(false);
          }}
        />
      </div>
      <div className="form__Group btn-required">
        <button className="btn" type="submit" disabled={isSubmitting || !isDirty || !caps || !isValid || loading }>
          {loading ? "Đang gửi đăng ký..." : "Đăng ký"}
        </button>
      </div>
    </form>
  );
};

export default FormRegister;
