import React, { useContext } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import BannerDetail from "../../components/UI/BannerDetail/BannerDetail";
import bannerProject from "../../assets/image/IntroduceSDC/BannerSDCIntroduce.png";
import { Link } from 'react-router-dom';
import "../../styles/PageContact/PageContact.css"
import { Col, Container, Row } from 'react-bootstrap';
import BoxPartners from '../Home/BoxPartners';
import FormRegisterContact from '../../components/UI/FormRegisterContact/FormRegisterContact';
import { ConfigContext } from '../../Context/ConfigContext';
import { Helmet } from 'react-helmet';
const PageContact = () => {
  const dataConfig = useContext(ConfigContext)
  const configs = dataConfig?.config
  const hotline = configs.hotline
  const address = configs.address
  const email = configs.email_address
  const titlevalue = configs?.title_page_contact
  const contentvalue = configs?.content_page_contact
  const workTime = configs.contact_time_work
  const timeSupport = configs.contact_title_support

  return (
    <>
      <Header />
      <Helmet>
        <title>Liên hệ</title>
        
      </Helmet>
      <BannerDetail imgBanner={bannerProject} titleH2="Liên Hệ" />
      <section>

        <div className='container'>
          <ul className='box-breadcrumbs'>
            <li className='item-breadcrumb'>

              <Link to='/'>Trang chủ</Link>

            </li>
            <li className="item-breadcrumb">
              <span>Liên hệ</span>
            </li>
          </ul>
        </div>
        <Container>
          <h2 className="text-center title-contact">{titlevalue}</h2>
          <div className="content-contact ">
            <Row className="justify-content-center text-center">
              <Col lg="11" md="8" className="contact-col">
                <p className="contact-des">{contentvalue}</p>
              </Col>
            </Row>
          </div>
          <div className="contact-info">
            <Row>
              {/* số điện thoại */}
              <Col lg="4">
                <div className="contact-info-item">
                  <div className="contact-info-icon">
                    <Link to={`tel: ${hotline}`}>
                      <i className="fa-solid fa-phone"></i>
                    </Link>
                  </div>
                  <p className='contact-info-details'>{hotline}</p>
                  <p className='contact-info-caps'>{timeSupport}</p>

                </div>
              </Col>
              {/* địa chỉ */}
              <Col lg="4">
                <div className="contact-info-item">
                  <div className="contact-info-icon">
                    <i className="fa-solid fa-map-location-dot"></i>
                  </div>
                  <p className='contact-info-details'>{address}</p>
                  <p className='contact-info-caps'>{workTime}</p>
                </div>
              </Col>
              {/* email */}
              <Col lg="4">
                <div className="contact-info-item">
                  <div className="contact-info-icon">
                    <Link to={`mailto:${email}`}>
                      <i className=" fa-regular fa-envelope-open"></i>
                    </Link>
                  </div>
                  <p className='contact-info-details'>{email}</p>
                  <p className='contact-info-caps'>{workTime}</p>

                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <FormRegisterContact />
      </section>

      <BoxPartners />
      <Footer />
    </>
  );
};

export default PageContact;
