import React, { useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import '../../styles/Lookup/Lookup.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import BoxPartners from '../Home/BoxPartners'
import { ConfigContext } from '../../Context/ConfigContext'
import FormRegisterContact from '../../components/UI/FormRegisterContact/FormRegisterContact'
import { Helmet } from 'react-helmet'
const LookupPoints = () => {
    const dataConfig = useContext(ConfigContext)
    const titleLookup = dataConfig?.config.lookup_points

    const [dataLookupPoint, setDataLookupPoint] = useState([])
    const [showList, setShowList] = useState(false)
    const { register, watch, handleSubmit, formState: { errors } } = useForm()
    const [isBirthday, setIsBirthday] = useState()
    const [isAdvanced, setIsAdvanced] = useState()

    const onHandleSubmit = (e) => {
        const type = e.type
        const key = e.key
        const value = e.value

        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/lookup/point?type=${type}&key=${key}&value=${value}`)
            .then((response) => {
                setDataLookupPoint(response.data.data);
                setShowList(true)
                setIsAdvanced(type)
            })
    }
    // hàm thay đổi input nếu chọn phương thức tra cứu là ngày sinh
    const watchKey = watch('key')
    useEffect(() => {
        setIsBirthday(watchKey)
    }, [watchKey])
    return (
        <div className='box-lookup-points'>
            <Header />
            <Helmet>
                <title>{titleLookup}</title>
            </Helmet>
            <main>
                <div className='breadcrumb-wrap'>
                    <div className='container'>
                        <ul className='breadcrumb justify-content-center'>
                            <li className='breadcrumb-item'>
                                <Link to='/'>Trang chủ</Link>
                            </li>
                            <li className='breadcrumb-item active'>Tra cứu điểm thi</li>
                        </ul>
                    </div>
                </div>
                <section className='section-lookup-content'>
                    <div className='container'>
                        <div className='section-title'>
                            <div className='title text-violet'>
                                <h2>
                                    <span>{titleLookup}</span>
                                </h2>
                            </div>
                        </div>
                        <div className='section-content'>
                            <div className='lookup-information'>
                                <form onSubmit={handleSubmit(onHandleSubmit)} >
                                    <div className="form-row align-items-center d-flex ">
                                        <div className="col-lg-3 my-1 ">
                                            <label htmlFor="type" >Chọn chứng chỉ:</label>
                                            <div className='input-group'>
                                                <select
                                                    className={errors?.type?.type === 'required' ? 'error form-control' : 'form-control'}
                                                    {...register('type', { required: true })}
                                                    name="type"
                                                    autoComplete='off'
                                                >
                                                    <option value=''>--Chọn chứng chỉ</option>
                                                    <option value='1'>Chứng chỉ CNTT cơ bản</option>
                                                    <option value='2'>Chứng chỉ CNTT nâng cao</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 my-1">
                                            <label htmlFor="key" >Chọn phương thức tra cứu:</label>
                                            <div className='input-group'>
                                                <select
                                                    className={errors?.key?.type === 'required' ? 'error form-control' : 'form-control'}
                                                    {...register('key', { required: true })}
                                                    name="key"
                                                >
                                                    <option value=''>--Chọn phương thức tra cứu </option>
                                                    <option value='1'>Mã học viên</option>
                                                    <option value='2'>Số CCCD/CMND</option>
                                                    <option value='3'>Họ và tên</option>
                                                    <option value='4'>Ngày sinh</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 my-1">
                                            <label htmlFor="information">Nhập dữ liệu:</label>
                                            <div className='input-group'>
                                                <input
                                                    type={isBirthday === '4' ? 'date' : 'text'}
                                                    className={errors?.value?.type === 'required' ? 'error form-control' : 'form-control'}
                                                    id="inlineFormInputGroupUsername"
                                                    name='information'
                                                    placeholder="Nhập dữ liệu..."
                                                    {...register('value', { required: true })}

                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-3 my-1  ">
                                            <button type="submit" className="btn btn-primary">Tra cứu</button>
                                        </div>
                                    </div>
                                </form>

                                {Object.keys(errors).length !== 0 && (
                                    <ul className='error-container'>
                                        {errors.type?.type === 'required' && <li>Vui lòng chọn Chứng chỉ</li>}
                                        {errors.key?.type === 'required' && <li>Vui lòng nhập Phương thức tra cứu</li>}
                                        {errors.value?.type === 'required' && <li>Vui lòng nhập Dữ liệu</li>}

                                    </ul>
                                )}
                                <section className={showList ? 'list-view d-block' : 'd-none list-view'}>


                                    {isAdvanced == 1 ?
                                        <table className='admindata '>
                                            <thead>
                                                <tr>
                                                    <th>STT</th>
                                                    <th>Mã học viên</th>
                                                    <th>Họ và tên</th>
                                                    <th>Ngày sinh</th>
                                                    <th>Điểm lý thuyết</th>
                                                    <th>Điểm thực hành</th>
                                                    <th>Kết quả</th>
                                                    <th>Khóa thi ngày</th>
                                                    <th>Quyết định số</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataLookupPoint?.length !== 0 ? dataLookupPoint?.map((data, index) => (
                                                    <tr key={index}>

                                                        <td>{index + 1}</td>
                                                        <td>{data.mahocvien}</td>
                                                        <td style={{ textAlign: 'left' }}>{data.fullname}</td>
                                                        <td>{data.birthday}</td>
                                                        <td>{data.lythuyet}</td>
                                                        <td>{data.thuchanh}</td>
                                                        <td>{data.rating_type}</td>
                                                        <td>{data.day_exam}</td>
                                                        <td>{data.decide_number}</td>


                                                    </tr>
                                                ))
                                                    : <tr >
                                                        <td colSpan='9'>Không có dữ liệu</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <table className='admindata '>
                                            <thead>
                                                <tr>
                                                    <th rowSpan={2}>STT</th>
                                                    <th rowSpan={2}>Mã học viên</th>
                                                    <th rowSpan={2}>Họ và tên</th>
                                                    <th rowSpan={2}>Ngày sinh</th>
                                                    <th colSpan={2}>Word</th>
                                                    <th colSpan={2}>Excel</th>
                                                    <th colSpan={2}>PowerPoint</th>
                                                    <th rowSpan={2}>Khóa thi ngày</th>
                                                    <th rowSpan={2}>Quyết định số</th>
                                                </tr>
                                                <tr>
                                                    <th>Điểm LT</th>
                                                    <th>Điểm TH</th>
                                                    <th>Điểm LT</th>
                                                    <th>Điểm TH</th>
                                                    <th>Điểm LT</th>
                                                    <th>Điểm TH</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataLookupPoint?.length !== 0 ? dataLookupPoint?.map((data, index) => (

                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.mahocvien}</td>
                                                        <td style={{ textAlign: 'left' }}>{data.fullname}</td>
                                                        <td>{data.birthday}</td>
                                                        <td>{data.word?.lythuyet}</td>
                                                        <td>{data.word?.thuchanh}</td>
                                                        <td>{data.excel?.lythuyet}</td>
                                                        <td>{data.excel?.thuchanh}</td>
                                                        <td>{data.power_point?.lythuyet}</td>
                                                        <td>{data.power_point?.thuchanh}</td>
                                                        <td>{data.day_exam}</td>
                                                        <td>{data.decide_number}</td>
                                                    </tr>
                                                ))
                                                    : <tr >
                                                        <td colSpan='9'>Không có dữ liệu</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <FormRegisterContact />
            <BoxPartners />
            <Footer />
        </div>
    )
}

export default LookupPoints