import React, { useRef, useState, useEffect, useContext } from "react";
import "../../styles/Header/header.css";
import { Link } from "react-router-dom";
import SubMenuParentDestop from "../MegaMenuDestop/SubMenuParentDestop";
import axios from "axios";
import { MenuContext } from "../../Context/MenuContext";
import { ConfigContext } from "../../Context/ConfigContext";
import { CategoriesContext } from "../../Context/CategoriesContext";
import Marquees from "../Marquee/Marquee";
// import Marquee from "react-fast-marquee";
const Header = () => {
  const dataMenu = useContext(MenuContext)
  const dataConfig = useContext(ConfigContext)
  const configs = dataConfig.config
  const slugCategories = useContext(CategoriesContext)
  const categories_slug = slugCategories.categories[0]?.slug
  const subMenu = {
    navbarNews: configs?.title_blog,
    navbarCourses: configs?.navbar_course,
    navbarLookup: configs?.navbar_lookup
  }
  const urlSoftware = configs?.url_software_sdc;
  const urlPersonVue = configs?.url_person_vue;
  const logo = localStorage.getItem("logo");

  const menu = dataMenu?.menu;
  const topMenu = menu?.slice(0, 6);
  const menuRef = useRef(null);
  const logoRef = useRef(null);
  const [menuCategory, setMenuCategory] = useState();

  const toggleMenu = () => {
    menuRef.current.classList.toggle("changeMenu");
    logoRef.current.classList.toggle("open");
    document.body.classList.toggle("hiddenScroll");
  };
  const removeHiddenScroll = () => {
    document.body.classList.remove("hiddenScroll");
  };

  const dataCategories = menuCategory?.categories
  const dataCourses = menuCategory?.course_categories

  const [subnav, setSubnav] = useState(false);
  const [showCourse, setShowCourse] = useState(false);
  const [showLookup, setShowLookup] = useState(false);
  const [showTrain, setShowTrain] = useState(false);
  const handleShowSubnav = () => {
    setSubnav(!subnav);
    setShowCourse(false);
    setShowLookup(false);
    setShowTrain(false)
  };
  const handleShowCourse = () => {
    setShowCourse(!showCourse);
    setSubnav(false);
    setShowLookup(false);
    setShowTrain(false)
  };
  const handleShowLookup = () => {
    setShowLookup(!showLookup);
    setSubnav(false);
    setShowCourse(false);
    setShowTrain(false)
  };
  const handleShowTrain = () => {
    setShowTrain(!showTrain);
    setSubnav(false);
    setShowCourse(false);
    setShowLookup(false);
  }
  useEffect(() => {


    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/menu-categories`)
      .then(response => {
        setMenuCategory(response.data.data)

      })

  }, []);

  return (
    <header className="header">
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="logo">
                <div className="logo-img">
                  <Link to="/" onClick={removeHiddenScroll}>
                    <img src={logo} alt="logo" />
                  </Link>
                </div>

                <div className="menu-btn" ref={logoRef} onClick={toggleMenu}>
                  <div className="menu-btn__burger"></div>
                </div>
              </div>
              <div className="navbar-collapse menu_collapse" ref={menuRef}>
                <ul className="clear mobi-menu">
                  {topMenu &&
                    topMenu.map((data, index) => (
                      <li className="dropdownmenu" key={index} >
                        <Link
                          to={`${data.slug === "phan-mem"
                            ? `${urlSoftware}`
                            : data.slug === "dao-tao"
                              ? `/tin-tuc-su-kien/${categories_slug}?page=1`
                              : `${data.slug === "khao-thi" ? `${urlPersonVue}` : `/${data.slug}`}`
                            }`}
                          target={`${data.slug === "phan-mem"
                            ? `_blank`
                            : `${data.slug === "khao-thi" ? `_blank` : ""}`
                            }`}
                          onClick={toggleMenu}

                        >
                          {data.title}
                        </Link>
                        {data.slug === "dao-tao" ?
                          <>
                            <i className={showTrain ? "fa-solid fa-angle-down" : "fa-solid fa-angle-right"} onClick={handleShowTrain}></i>
                            {showTrain &&
                              <ul className="sub-menu-course-mobile">
                                {/* <li className="dropdownmenu m-0" onClick={toggleMenu}>
                                  <Link to='/giang-vien'>giảng viên - chuyên gia</Link>
                                </li>
                                <li className="dropdownmenu m-0" onClick={toggleMenu}>
                                  <Link to='/cuu-hoc-vien'>cựu học viên</Link>
                                </li>
                                <li className="dropdownmenu m-0" onClick={toggleMenu}>
                                  <Link to='/du-an-hoc-vien/san-pham?page=1'>sản phẩm học viên</Link>
                                </li>
                                <li className="dropdownmenu m-0" onClick={toggleMenu}>
                                  <Link to="/lich-thi?page=1">lịch thi</Link>
                                </li>
                                <li className="dropdownmenu m-0" onClick={toggleMenu}>
                                  <Link to='/khoa-hoc'>khóa học</Link>
                                </li> */}
                                {data.children.map((item, index) => (
                                  <li className="dropdownmenu m-0" key={index} onClick={toggleMenu}>
                                    <Link
                                      to={`${item.slug === "san-pham-hoc-vien" ? `/du-an-hoc-vien/san-pham?page=1` : item.slug === "lich-thi" ? `/lich-thi?page=1` : item.slug === "lich-khai-giang" ? `/lich-khai-giang?khoa-hoc=lap-trinh` : `/${item.slug}`}`}
                                    >{item.title}</Link>
                                  </li>
                                ))}
                              </ul>
                            }</>
                          : ''
                        }

                      </li>
                    ))}
                  <li className="dropdownmenu">
                    <span>{subMenu.navbarCourses}</span>
                    <i className={showCourse ? "fa-solid fa-angle-down" : "fa-solid fa-angle-right"} onClick={handleShowCourse}></i>
                    {showCourse && (
                      <ul className="sub-menu-course-mobile">
                        {dataCourses?.map((course, index) => (
                          <li className="dropdownmenu" key={index} onClick={toggleMenu}>
                            <Link to={`/khoa-hoc/${course.slug}`}>{course.title}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                  <li className="dropdownmenu">
                    <span>{subMenu.navbarNews}</span>
                    <i className={subnav ? "fa-solid fa-angle-down" : "fa-solid fa-angle-right"} onClick={handleShowSubnav}></i>
                    {subnav && (
                      <ul className="sub-menu-news-mobile">
                        {dataCategories?.map((category, index) => (
                          <li className="dropdownmenu" key={index} onClick={toggleMenu}>
                            <Link to={`/tin-tuc-su-kien/${category.slug}?page=1`}>{category.title}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>

                  <li className="dropdownmenu">
                    <span>{subMenu.navbarLookup}</span>
                    <i className={showLookup ? "fa-solid fa-angle-down" : "fa-solid fa-angle-right"} onClick={handleShowLookup}></i>

                    {showLookup && (
                      <ul className="sub-menu">
                        <li className="dropdownmenu" onClick={toggleMenu}>
                          <Link to="/tra-cuu-diem-thi">Tra cứu điểm thi</Link>
                        </li>
                        <li className="dropdownmenu" onClick={toggleMenu}>
                          <Link to="/tra-cuu-van-bang">Tra cứu văn bằng</Link>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
              {/* Ở trên là mobile */}
            </div>
            <div className="col-lg-9">
              <nav>
                <ul className="main-menu">
                  {topMenu &&
                    topMenu.map((data, index) => {
                      return <SubMenuParentDestop data={data} key={index} />;
                    })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="bot-header">
        <div className="container">
          <ul className="bot-header-menu">
            <li className="dropdownmenu">
              <span>{subMenu.navbarCourses}</span>
              <ul className="sub-menu">

                {dataCourses?.map((course, index) => (
                  <li className="dropdownmenu" key={index}>
                    <Link to={`/khoa-hoc/${course.slug}`}>{course.title}</Link>
                  </li>
                ))}
              </ul>
            </li>

            <li className="dropdownmenu">
              <span>{subMenu.navbarNews}</span>
              <ul className="sub-menu">
                {dataCategories?.map((category, index) => (
                  <li className="dropdownmenu" key={index}>
                    <Link to={`/tin-tuc-su-kien/${category.slug}?page=1`}>{category.title}</Link>
                  </li>
                ))}
              </ul>
            </li>

            <li className="dropdownmenu">
              <span>{subMenu.navbarLookup}</span>
              <ul className=" sub-menu-lookup">
                <li className="dropdownmenu">
                  <Link to="/tra-cuu-diem-thi">Tra cứu điểm thi</Link>
                </li>
                <li className="dropdownmenu">
                  <Link to="/tra-cuu-van-bang">Tra cứu văn bằng</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <Marquees />

      </div>
    </header>
  );
};

export default Header;
